<template>
  <div class="education-catalog">
    <div class="row types">
      <div :class="'col-' + (summary.length === 2? '50': '33')" class="type" v-for="type in summary" :key="type.id">
        <div class="education-catalog__item education-catalog__item--wide"
             :class="colors[$getRandomColor(colors.length)]"
             :style="type.id === 19 ? 'padding: 20px 0 20px 20px;' : ''">
          <div class="education-catalog__left">
            <div class="education-catalog__item-title" @click="$router.push(getRoute(type))">
              {{ type.title }}
            </div>

            <div class="education-catalog__list">
              <div v-for="item in type.items" :key="item.id">
                <div class="education-catalog__list-item">
                  <div class="education-catalog__list-item-name" @click="getLinkForItem(item)">
                    {{ item.dictionary_id.name }}
                  </div>

                  <div class="education-catalog__list-item-counter">
                    {{ item.count }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="education-catalog__right">
            <!--шапка с лупой-->
            <svg v-if="[12,15].includes(type.id) " width="135" height="135" viewBox="0 0 135 135" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path opacity="0.1"
                      d="M97.8151 60.9141C97.815 60.9158 97.8135 60.9174 97.8135 60.919C97.8135 60.9173 97.815 60.9158 97.8151 60.9141Z"
                      fill="black"/>
                <path
                    d="M135 76.5944L134.488 76.6971C134.452 76.6726 134.415 76.6482 134.375 76.625L119.918 68.2719L117.356 69.7622L130.792 77.5257L68.3931 113.816L23.2275 87.7194L85.6265 51.4295L99.0636 59.1933L101.626 57.703L87.1691 49.3498C86.3393 48.8704 84.9854 48.8704 84.161 49.3498L20.0765 86.6199L18.8252 86.9171V87.8163L18.8272 87.8176C18.8086 88.1484 19.0147 88.4831 19.4492 88.7342L66.6554 116.009C67.4852 116.489 68.8386 116.489 69.6635 116.009L134.385 78.3687C134.794 78.1307 134.999 77.8174 135 77.5035L135.001 77.5032L135 76.5944Z"
                    fill="#9E4F00"/>
                <path
                    d="M134.385 77.4644L69.6633 115.105C68.8382 115.585 67.4848 115.584 66.6547 115.105L19.449 87.8302C18.6189 87.3501 18.6138 86.5658 19.4389 86.0857L84.161 48.446C84.9851 47.9659 86.3394 47.9659 87.1686 48.446L101.626 56.7985L99.0636 58.2893L97.0549 59.4577C96.9933 59.493 96.9943 59.5506 97.0549 59.586L115.124 70.0266C115.185 70.062 115.285 70.062 115.346 70.0266L117.356 68.8582L119.918 67.3674L134.375 75.7209C135.204 76.2 135.209 76.9853 134.385 77.4644Z"
                    fill="#D06E0B"/>
                <path d="M130.685 76.6077L86.2231 50.918L24.7969 86.6421L69.2591 112.332L130.685 76.6077Z"
                      fill="white"/>
                <path
                    d="M120.62 65.9869L120.461 66.004C120.342 65.819 120.156 65.6454 119.9 65.4974L115.647 63.0402C117.378 62.0335 117.369 60.4014 115.626 59.3947C113.885 58.3883 111.069 58.388 109.338 59.3948L105.085 56.9378C104.129 56.3853 102.569 56.3853 101.619 56.9378L101.002 57.297L98.439 58.7873L96.9935 59.628L97.0251 59.4896L96.3848 59.5285V60.0149L96.3863 60.0154C96.3842 60.0404 96.3982 60.0657 96.4309 60.0846L114.5 70.5245C114.561 70.5599 114.661 70.5599 114.722 70.5245L116.731 69.3562L119.294 67.8659L119.911 67.5067C120.366 67.2418 120.6 66.8961 120.617 66.5471L120.62 66.5439V65.9869Z"
                    fill="#E9A35D"/>
                <path
                    d="M119.9 64.9994L115.647 62.5422C117.378 61.5354 117.369 59.9034 115.626 58.8967C113.885 57.8903 111.069 57.89 109.338 58.8967L105.085 56.4398C104.129 55.8873 102.569 55.8873 101.619 56.4398L101.002 56.7989L98.439 58.2893L96.4301 59.4576C96.3694 59.493 96.3697 59.5512 96.4309 59.5865L114.5 70.0265C114.561 70.0619 114.661 70.0619 114.722 70.0265L116.731 68.8582L119.294 67.3678L119.911 67.0087C120.861 66.4561 120.856 65.5519 119.9 64.9994Z"
                    fill="#F8D6B5"/>
                <path
                    d="M71.3136 37.9219H15.0664C15.0664 49.1461 15.0664 60.3702 15.0664 71.5946C15.0696 71.5967 15.0731 71.5989 15.0763 71.601C15.2569 75.6433 18.0186 79.6579 23.3573 82.7425C34.3615 89.1006 52.1445 89.1002 63.0766 82.7423C68.3597 79.6698 71.0757 75.6749 71.2308 71.6491C71.2583 71.631 71.2867 71.6124 71.3136 71.5946V37.9219Z"
                    fill="#495469"/>
                <path d="M42.9078 58.4958L86.3802 33.7768L42.9078 8.77539L0 33.6356L42.9078 58.4958Z" fill="#495469"/>
                <path d="M42.9082 58.4963V61.3314L86.3806 36.6124V33.7773L42.9082 58.4963Z" fill="#2E3648"/>
                <path d="M42.9078 58.495V61.3301L0 36.4699V33.6348L42.9078 58.495Z" fill="#6F7A90"/>
                <path d="M42.7393 29.8831L63.5178 46.7028V58.6936H64.5047V46.1416L43.0756 29.6035L42.7393 29.8831Z"
                      fill="#E9A35D"/>
                <path
                    d="M64.0267 75.0173C62.3309 75.0173 60.9434 73.6287 60.9434 71.9315L61.6079 62.4978C61.9365 59.9538 63.1722 59.4121 64.0267 59.4121C64.0825 59.4121 65.671 59.5363 66.2804 62.4978L67.1101 71.9315C67.1101 73.6287 65.7226 75.0173 64.0267 75.0173Z"
                    fill="#E9A35D"/>
                <path
                    d="M64.7237 60.7508H63.2369C62.9825 60.7508 62.7744 60.5884 62.7744 60.3899V58.4117C62.7744 58.2132 62.9825 58.0508 63.2369 58.0508H64.7237C64.978 58.0508 65.1861 58.2132 65.1861 58.4117V60.3899C65.1861 60.5884 64.978 60.7508 64.7237 60.7508Z"
                    fill="#D06E0B"/>
                <path
                    d="M98.6507 85.9159C95.3061 83.9769 90.8419 83.0819 86.4703 83.2308C82.7223 83.3584 79.0423 84.2532 76.194 85.9153C73.1285 87.7041 71.5937 90.0499 71.5866 92.3995L71.5752 96.4273C71.5823 94.0778 73.1171 91.7319 76.1826 89.9431C79.0309 88.2811 82.7109 87.3862 86.4589 87.2586C90.8306 87.1098 95.2947 88.0048 98.6394 89.9437C101.765 91.7559 103.326 94.1401 103.319 96.5206L103.33 92.4927C103.338 90.1123 101.777 87.728 98.6507 85.9159Z"
                    fill="#A2ABBE"/>
                <g opacity="0.5">
                  <path opacity="0.5"
                        d="M98.6501 87.6971C104.861 91.2977 104.894 97.1568 98.7233 100.757C92.5515 104.359 82.4775 104.359 76.2666 100.758C70.0544 97.157 70.0216 91.2978 76.1934 87.6965C82.3639 84.0959 92.438 84.0958 98.6501 87.6971Z"
                        fill="#81ABEE"/>
                </g>
                <path
                    d="M104.642 96.4984C103.718 97.835 102.356 99.0331 100.595 100.061C98.8333 101.089 96.7771 101.885 94.4822 102.427C92.2654 102.95 89.9231 103.215 87.5185 103.216C85.1146 103.216 82.7688 102.95 80.5468 102.427C78.7703 102.008 77.1344 101.438 75.6649 100.727C75.2315 100.517 74.8123 100.295 74.4086 100.061C74.0047 99.8271 73.6216 99.5841 73.2595 99.3327C72.0315 98.4801 71.046 97.5301 70.3205 96.4982C69.4132 95.2075 68.9496 93.8444 68.9412 92.446C68.941 92.4288 68.941 92.4116 68.9411 92.3945L68.9297 96.4224C68.9297 96.4395 68.9297 96.4566 68.9298 96.4738C68.9382 97.8723 69.4019 99.2354 70.3091 100.526C71.0346 101.558 72.0201 102.508 73.2481 103.361C73.6102 103.612 73.9934 103.855 74.3972 104.089C74.801 104.323 75.2202 104.545 75.6536 104.755C77.123 105.466 78.759 106.036 80.5354 106.454C82.7574 106.978 85.1033 107.243 87.5072 107.243C89.9117 107.243 92.254 106.978 94.4708 106.455C96.7657 105.913 98.8219 105.117 100.584 104.089C102.345 103.061 103.707 101.863 104.631 100.526C105.511 99.252 105.96 97.9071 105.964 96.5279L105.975 92.5001C105.971 93.8792 105.523 95.2241 104.642 96.4984Z"
                    fill="#A2ABBE"/>
                <path
                    d="M104.642 96.498C103.718 97.8346 102.357 99.0327 100.595 100.06C98.8337 101.088 96.7775 101.885 94.4825 102.426C92.2657 102.95 89.9235 103.215 87.5189 103.215C85.115 103.215 82.7692 102.95 80.5471 102.426C78.7707 102.008 77.1347 101.438 75.6653 100.727C75.2319 100.517 74.8127 100.295 74.4089 100.061C74.0051 99.8268 73.6219 99.5838 73.2598 99.3323C72.0318 98.4797 71.0464 97.5297 70.3208 96.4978C69.4136 95.2072 68.9499 93.844 68.9415 92.4456C68.9343 91.047 69.3826 89.6839 70.2754 88.3932C71.1999 87.0571 72.5618 85.8582 74.3235 84.8301C76.0847 83.8025 78.1408 83.0069 80.4352 82.4648C82.6514 81.9413 84.9943 81.6758 87.3982 81.6758C89.8027 81.6761 92.148 81.9413 94.3706 82.4644C96.6716 83.0061 98.7368 83.8024 100.51 84.8304C102.283 85.858 103.658 87.0562 104.597 88.3927C105.504 89.6837 105.968 91.0472 105.976 92.4454C105.983 93.8435 105.534 95.207 104.642 96.498ZM76.2675 98.9764C82.4784 102.577 92.5524 102.577 98.7242 98.9755C104.895 95.3749 104.862 89.5158 98.651 85.9153C92.4388 82.314 82.3648 82.314 76.1942 85.9146C70.0224 89.516 70.0553 95.3751 76.2675 98.9764Z"
                    fill="#CDD3DF"/>
                <path
                    d="M73.6378 100.88C73.3588 100.719 73.106 100.704 72.9235 100.81L66.1094 104.786C66.2919 104.68 66.5447 104.695 66.8237 104.857C67.3779 105.178 67.8259 105.957 67.8241 106.596C67.8232 106.914 67.7114 107.138 67.5312 107.243L74.3453 103.267C74.5255 103.162 74.6373 102.938 74.6382 102.62C74.64 101.981 74.192 101.202 73.6378 100.88Z"
                    fill="#495469"/>
                <path
                    d="M66.7607 102.923C66.0165 102.492 65.3422 102.452 64.8554 102.736L46.6807 113.341C47.1675 113.057 47.8417 113.097 48.586 113.529C50.0642 114.386 51.259 116.464 51.2542 118.169C51.2518 119.017 50.9537 119.613 50.4731 119.894L68.6478 109.288C69.1285 109.008 69.4265 108.412 69.4289 107.564C69.4337 105.858 68.2389 103.78 66.7607 102.923Z"
                    fill="#6F7A90"/>
                <path
                    d="M48.5866 113.529C50.0648 114.386 51.2596 116.464 51.2548 118.169C51.25 119.876 50.0474 120.564 48.5691 119.707C47.0904 118.85 45.8956 116.772 45.9004 115.065C45.9052 113.36 47.1078 112.672 48.5866 113.529Z"
                    fill="#495469"/>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="135" height="135" fill="white"/>
                </clipPath>
              </defs>
            </svg>

            <!--деньги-->
            <svg v-if="type.id === 19" width="131" height="232" viewBox="0 0 131 232" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M205.72 223.952L205.659 245.458L122.943 197.704L123.005 176.195L205.72 223.952Z" fill="#C7E6A8"/>
              <path
                  d="M200.478 217.741C197.817 216.204 197.805 213.714 200.447 212.177C203.092 210.64 207.39 210.64 210.052 212.177C212.713 213.714 212.727 216.204 210.083 217.741C207.44 219.276 203.14 219.278 200.478 217.741ZM160.061 163.698C157.4 162.161 157.388 159.671 160.03 158.134C162.675 156.597 166.973 156.597 169.635 158.134C172.295 159.669 172.31 162.161 169.666 163.698C167.023 165.233 162.721 165.233 160.061 163.698ZM247.274 199.768L228.868 189.141L225.873 190.882L190.25 211.584L187.255 213.325L141.349 186.822L138.333 185.081L179.948 160.898L164.558 152.012L122.943 176.195L205.659 223.952L247.274 199.768Z"
                  fill="#F6FCF3"/>
              <path d="M82.7771 153.077L82.7154 174.583L0 126.829L0.0616288 105.32L82.7771 153.077Z" fill="#C7E6A8"/>
              <path d="M64.3717 142.45L64.3101 163.958L18.4062 137.454L18.466 115.945L64.3717 142.45Z" fill="#8DC95E"/>
              <path d="M124.389 128.893L124.328 150.399L82.7148 174.583L82.7765 153.076L124.389 128.893Z"
                    fill="#C7E6A8"/>
              <path
                  d="M77.5348 146.866C74.8732 145.329 74.8616 142.839 77.504 141.302C80.1482 139.765 84.4468 139.765 87.1084 141.302C89.77 142.839 89.7835 145.329 87.1392 146.866C84.4969 148.401 80.1964 148.403 77.5348 146.866ZM37.1179 92.8231C34.4563 91.2862 34.4447 88.796 37.087 87.2592C39.7313 85.7223 44.0299 85.7223 46.6915 87.2592C49.3512 88.7941 49.3666 91.2862 46.7223 92.8231C44.08 94.358 39.7775 94.358 37.1179 92.8231ZM124.33 128.893L105.924 118.266L102.93 120.007L67.3063 140.709L64.3116 142.45L18.4058 115.947L15.3899 114.206L57.0047 90.0228L41.6148 81.1367L0 105.32L82.7155 153.077L124.33 128.893Z"
                  fill="#F6FCF3"/>
              <path
                  d="M71.6843 113.563C70.9159 113.12 69.9337 112.588 68.8879 112.018L62.933 115.479L65.6389 117.041C67.8518 118.318 70.367 118.886 72.5356 117.627C74.7638 116.332 74.2361 115.036 71.6843 113.563ZM53.3613 113.209L56.1693 111.577L59.1814 113.316L68.6491 107.814C69.849 108.505 73.2905 110.424 74.7966 111.292C81.1578 114.965 80.3778 117.698 76.8669 119.739C73.2 121.869 68.054 121.692 62.9523 118.745L60.1251 117.112L57.5906 118.586L62.6326 121.496L59.8227 123.129L54.7807 120.219L51.0233 122.403L47.2736 120.238L51.031 118.054L48.0189 116.315L50.8288 114.682L53.8409 116.421L56.3754 114.948L53.3613 113.209ZM60.0211 91.7617L18.4062 115.947L64.312 142.451L18.4062 115.947L21.401 114.206L67.3068 140.71L102.93 120.009L57.0244 93.5027L60.0211 91.7617Z"
                  fill="#C7E6A8"/>
              <path
                  d="M62.9329 115.481L68.8878 112.021C69.9335 112.589 70.9157 113.12 71.6842 113.565C74.236 115.039 74.7637 116.335 72.5335 117.629C70.3649 118.888 67.8497 118.32 65.6369 117.043L62.9329 115.481ZM74.7964 111.293C73.2904 110.422 69.8469 108.506 68.649 107.814L59.1812 113.317L56.1691 111.578L53.3612 113.209L56.3733 114.948L53.8388 116.421L50.8267 114.682L48.0168 116.315L51.0289 118.054L47.2715 120.238L51.0212 122.403L54.7786 120.219L59.8206 123.129L62.6305 121.496L57.5885 118.586L60.123 117.113L62.9502 118.746C68.0519 121.691 73.1999 121.87 76.8648 119.74C80.3777 117.698 81.1596 114.965 74.7964 111.293Z"
                  fill="#57A003"/>
              <path
                  d="M46.6916 87.2581C44.03 85.7213 39.7314 85.7213 37.0871 87.2581C34.4448 88.7931 34.4583 91.2852 37.1179 92.822C39.7776 94.357 44.0801 94.357 46.7224 92.822C49.3667 91.2852 49.3513 88.7931 46.6916 87.2581Z"
                  fill="#8DC95E"/>
              <path
                  d="M87.1086 141.303C84.447 139.766 80.1484 139.766 77.5041 141.303C74.8618 142.838 74.8753 145.33 77.5349 146.867C80.1965 148.404 84.4971 148.402 87.1394 146.867C89.7837 145.328 89.7682 142.838 87.1086 141.303Z"
                  fill="#8DC95E"/>
              <path d="M60.0214 91.7625L57.0055 90.0234L15.3906 114.207L18.4066 115.948L60.0214 91.7625Z"
                    fill="#FBE9EC"/>
              <path d="M67.3068 140.709L21.401 114.207L18.4062 115.948L64.3101 142.45L67.3068 140.709Z" fill="#8DC95E"/>
              <path d="M105.925 118.266L60.0211 91.7617L57.0244 93.5027L102.93 120.007L105.925 118.266Z"
                    fill="#8DC95E"/>
              <path d="M165.221 106.796L165.16 128.302L82.4443 80.5475L82.506 59.0391L165.221 106.796Z" fill="#C7E6A8"/>
              <path d="M146.816 96.1683L146.754 117.677L100.851 91.1725L100.91 69.6641L146.816 96.1683Z"
                    fill="#8DC95E"/>
              <path
                  d="M159.979 100.584C157.318 99.0476 157.306 96.5574 159.948 95.0206C162.593 93.4837 166.891 93.4837 169.553 95.0206C172.214 96.5574 172.228 99.0476 169.584 100.584C166.941 102.119 162.641 102.121 159.979 100.584ZM119.562 46.5418C116.901 45.005 116.889 42.5148 119.531 40.9779C122.176 39.441 126.474 39.441 129.136 40.9779C131.796 42.5128 131.811 45.005 129.167 46.5418C126.524 48.0768 122.222 48.0768 119.562 46.5418ZM206.775 82.612L188.369 71.9849L185.374 73.7259L149.751 94.4274L146.756 96.1684L100.85 69.6661L97.8342 67.9251L139.449 43.7416L124.059 34.8555L82.4443 59.039L165.16 106.796L206.775 82.612Z"
                  fill="#F6FCF3"/>
              <path
                  d="M154.129 67.2816C153.36 66.8387 152.378 66.3071 151.332 65.7371L145.377 69.1979L148.083 70.7598C150.296 72.0367 152.811 72.6048 154.98 71.3453C157.208 70.0511 156.68 68.755 154.129 67.2816ZM135.806 66.9273L138.614 65.296L141.626 67.0351L151.093 61.5328C152.293 62.2242 155.735 64.1424 157.241 65.011C163.602 68.6837 162.822 71.4166 159.311 73.458C155.644 75.5881 150.498 75.4109 145.397 72.4642L142.569 70.8311L140.035 72.3044L145.077 75.2144L142.267 76.8476L137.225 73.9376L133.468 76.1215L129.718 73.9568L133.475 71.7728L130.463 70.0338L133.273 68.4006L136.285 70.1397L138.82 68.6664L135.806 66.9273ZM142.465 45.4805L100.851 69.6659L146.756 96.1701L100.851 69.6659L103.845 67.9249L149.751 94.4291L185.374 73.7276L139.469 47.2215L142.465 45.4805Z"
                  fill="#C7E6A8"/>
              <path
                  d="M145.377 69.2002L151.332 65.7394C152.378 66.3075 153.36 66.8391 154.129 67.2839C156.68 68.7573 157.208 70.0534 154.978 71.3476C152.809 72.6071 150.294 72.039 148.081 70.7621L145.377 69.2002ZM157.241 65.0114C155.735 64.1409 152.291 62.2246 151.093 61.5332L141.626 67.0355L138.613 65.2964L135.806 66.9276L138.818 68.6667L136.283 70.14L133.271 68.401L130.461 70.0341L133.473 71.7732L129.716 73.9572L133.466 76.1219L137.223 73.9379L142.265 76.848L145.075 75.2148L140.033 72.3048L142.567 70.8314L145.395 72.4646C150.496 75.4093 155.644 75.5884 159.309 73.4584C162.822 71.4169 163.604 68.6841 157.241 65.0114Z"
                  fill="#57A003"/>
              <path
                  d="M129.136 40.9769C126.474 39.44 122.176 39.44 119.531 40.9769C116.889 42.5118 116.903 45.0039 119.562 46.5408C122.222 48.0757 126.524 48.0757 129.167 46.5408C131.811 45.0039 131.796 42.5118 129.136 40.9769Z"
                  fill="#8DC95E"/>
              <path d="M142.466 45.4813L139.45 43.7422L97.835 67.9257L100.851 69.6667L142.466 45.4813Z" fill="#FBE9EC"/>
              <path d="M149.751 94.4281L103.845 67.9258L100.851 69.6668L146.754 96.1691L149.751 94.4281Z"
                    fill="#8DC95E"/>
            </svg>

            <!--мяч и краски-->
            <svg v-if="type.id === 20" width="135" height="135" viewBox="0 0 135 135" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path
                    d="M135 52.1068C135 62.3872 130.6 71.6398 123.584 78.0762C122.327 79.2357 120.981 80.2995 119.563 81.2616C118.184 82.2067 116.729 83.0501 115.215 83.7878C113.672 84.5456 112.06 85.1911 110.398 85.7116C107.054 86.7689 103.494 87.3387 99.8013 87.3387C97.2182 87.3387 94.7006 87.0587 92.2752 86.5284C91.6959 86.4066 91.1234 86.265 90.5542 86.107C81.5471 83.6628 73.9747 77.727 69.3809 69.838C69.2656 69.6469 69.1571 69.456 69.055 69.2616C66.2183 64.1856 64.6025 58.3357 64.6025 52.1068C64.6025 48.0091 65.3 44.0762 66.587 40.4199C66.7909 39.8302 67.0147 39.2472 67.2549 38.6708C69.2327 33.8682 72.2438 29.5994 76.0086 26.1441C77.2689 24.9845 78.615 23.9175 80.0332 22.9524C81.4187 22.0068 82.8765 21.1603 84.3969 20.4193C85.9435 19.665 87.5494 19.0194 89.2114 18.4988C92.5548 17.4448 96.1122 16.875 99.8013 16.875C106.669 16.875 113.08 18.8447 118.497 22.2507C118.895 22.501 119.286 22.7577 119.675 23.0247C122.985 25.2943 125.888 28.1139 128.251 31.3585C128.511 31.714 128.764 32.0731 129.007 32.4388C131.492 36.1248 133.299 40.3113 134.243 44.8141C134.342 45.2785 134.431 45.7429 134.506 46.214C134.832 48.131 135 50.0975 135 52.1068Z"
                    fill="#E9A35D"/>
                <path
                    d="M100.137 22.0555C96.3292 19.8518 92.6565 18.6561 89.211 18.498C87.549 19.0185 85.9432 19.6642 84.3965 20.4185C85.634 20.0263 86.9766 19.8253 88.4146 19.8253C91.8471 19.8253 95.5656 20.9684 99.4621 23.2217C113.336 31.239 124.62 50.8049 124.62 66.8365C124.62 73.2859 122.794 78.2797 119.562 81.2608C120.981 80.2987 122.327 79.2349 123.584 78.0754C125.15 75.0284 125.97 71.2074 125.97 66.8365C125.97 50.3768 114.379 30.287 100.137 22.0555Z"
                    fill="#643302"/>
                <path
                    d="M113.123 42.9889C113.07 42.8969 113.014 42.8044 112.955 42.7154C112.731 42.34 112.487 41.9744 112.227 41.6187C108.246 36.0948 100.943 33.0742 91.4524 33.0742C83.8274 33.0742 75.4557 35.0308 67.2549 38.6705C67.0147 39.2469 66.7908 39.8299 66.5869 40.4196C75.0047 36.5032 83.6368 34.3916 91.4524 34.3916C100.466 34.3916 107.374 37.1982 111.115 42.3302C111.378 42.6826 111.622 43.0483 111.846 43.4269C111.898 43.5029 111.941 43.5787 111.987 43.6544C115.449 49.5999 114.738 57.5381 109.983 66.0035C105.823 73.4084 99.018 80.4277 90.5541 86.1066C91.1233 86.2647 91.6958 86.4063 92.2752 86.528C100.45 80.8559 107.034 73.942 111.132 66.6493C116.124 57.759 116.831 49.3559 113.123 42.9889Z"
                    fill="#643302"/>
                <path
                    d="M134.243 44.8133C128.638 43.6044 124.334 40.9628 121.757 37.0793C121.586 36.8288 121.425 36.5685 121.27 36.305C121.201 36.1899 121.135 36.0712 121.073 35.9526C119.01 32.1942 118.625 27.7441 119.674 23.024C119.286 22.757 118.894 22.5003 118.496 22.25C117.2 27.6091 117.673 32.5468 119.961 36.6641C120.013 36.7663 120.073 36.8683 120.132 36.9705C120.293 37.2506 120.464 37.5238 120.645 37.7907C123.498 42.1023 128.283 44.9846 134.506 46.2133C134.43 45.7422 134.341 45.2778 134.243 44.8133Z"
                    fill="#643302"/>
                <path
                    d="M129.007 32.4396L121.758 37.0809L120.645 37.7923L117.476 39.8214L116.357 40.5393L112.955 42.7166L111.846 43.4281L69.733 70.3923L69.3809 69.8389L69.0254 69.2824L69.0549 69.2625L111.115 42.3314L112.227 41.6199L115.656 39.4229L116.772 38.7079L119.961 36.6657L121.073 35.9542L128.251 31.3594C128.511 31.7149 128.764 32.074 129.007 32.4396Z"
                    fill="#643302"/>
                <path
                    d="M60.4184 117.07L86.4748 102.012C87.5163 101.41 88.0351 100.62 88.0312 99.8315L88.0313 94.6461H84.8958V96.7561L35.1284 67.9952C33.0532 66.7959 29.6884 66.7959 27.613 67.9952L3.13559 82.1409V80.067H0V85.2246C0 85.2249 0 85.2252 0 85.2255V85.2525C0.0119549 86.0293 0.53094 86.804 1.55653 87.3966L52.9028 117.07C54.9782 118.269 58.3431 118.269 60.4184 117.07Z"
                    fill="#C0D6F6"/>
                <path
                    d="M86.4748 96.8538L60.4184 111.912C58.3431 113.111 54.9782 113.111 52.9028 111.912L1.55653 82.2385C-0.518844 81.0392 -0.518844 79.0947 1.55653 77.8953L27.613 62.837C29.6884 61.6377 33.0532 61.6377 35.1284 62.837L86.4748 92.5105C88.5501 93.7099 88.5501 95.6544 86.4748 96.8538Z"
                    fill="#F6F9FE"/>
                <path
                    d="M12.5016 77.5095C15.3018 75.8913 19.8416 75.8913 22.6417 77.5095C23.7818 78.1685 24.4496 78.9946 24.6612 79.8512C24.9693 78.6043 24.3017 77.2922 22.6417 76.3328C19.8416 74.7146 15.3018 74.7146 12.5016 76.3328C10.8418 77.2922 10.174 78.6043 10.4821 79.8512C10.6937 78.9946 11.3615 78.1685 12.5016 77.5095Z"
                    fill="#091F52"/>
                <path
                    d="M12.502 82.1919C15.3021 83.8101 19.842 83.8101 22.6421 82.1919C23.7822 81.5331 24.4499 80.7068 24.6616 79.8503C24.4499 78.9937 23.7822 78.1676 22.6421 77.5086C19.842 75.8904 15.3021 75.8904 12.502 77.5086C11.3619 78.1676 10.6941 78.9937 10.4824 79.8503C10.6941 80.7068 11.3619 81.5331 12.502 82.1919Z"
                    fill="#113079"/>
                <path
                    d="M26.3308 69.5174C29.1308 67.8991 33.6706 67.8991 36.4708 69.5174C37.611 70.1761 38.2786 71.0023 38.4903 71.859C38.7984 70.6119 38.1306 69.2999 36.4708 68.3406C33.6706 66.7224 29.1308 66.7224 26.3308 68.3406C24.6708 69.2999 24.003 70.6119 24.3112 71.859C24.5229 71.0023 25.1905 70.1761 26.3308 69.5174Z"
                    fill="#AB213A"/>
                <path
                    d="M26.3311 74.1997C29.1311 75.8179 33.671 75.8179 36.4711 74.1997C37.6114 73.5408 38.2789 72.7146 38.4906 71.8581C38.2789 71.0014 37.6114 70.1752 36.4711 69.5164C33.671 67.8982 29.1311 67.8982 26.3311 69.5164C25.1909 70.1752 24.5232 71.0014 24.3115 71.8581C24.5232 72.7146 25.1909 73.5408 26.3311 74.1997Z"
                    fill="#E14761"/>
                <path
                    d="M25.5211 85.033C28.3211 83.4148 32.861 83.4148 35.6612 85.033C36.8013 85.6919 37.469 86.5181 37.6808 87.3746C37.9888 86.1277 37.3211 84.8155 35.6612 83.8563C32.861 82.238 28.3211 82.238 25.5211 83.8563C23.8611 84.8155 23.1935 86.1277 23.5016 87.3746C23.7133 86.5181 24.3809 85.6919 25.5211 85.033Z"
                    fill="#214EB0"/>
                <path
                    d="M25.5205 89.7172C28.3205 91.3354 32.8604 91.3354 35.6606 89.7172C36.8007 89.0584 37.4684 88.2323 37.6801 87.3756C37.4684 86.5191 36.8007 85.6929 35.6606 85.0339C32.8604 83.4158 28.3205 83.4158 25.5205 85.0339C24.3803 85.6929 23.7126 86.5191 23.501 87.3756C23.7126 88.2323 24.3803 89.0584 25.5205 89.7172Z"
                    fill="#3D75E4"/>
                <path
                    d="M37.3307 79.3824C37.5424 78.5259 38.21 77.6998 39.3502 77.0408C42.1504 75.4226 46.6902 75.4226 49.4902 77.0408C50.6304 77.6998 51.2981 78.5259 51.5098 79.3824C51.818 78.1354 51.1502 76.8233 49.4902 75.8641C46.6902 74.2458 42.1504 74.2458 39.3502 75.8641C37.6902 76.8233 37.0226 78.1354 37.3307 79.3824Z"
                    fill="#F196A5"/>
                <path
                    d="M49.4905 77.0417C46.6905 75.4236 42.1507 75.4236 39.3505 77.0417C38.2103 77.7007 37.5427 78.5269 37.3311 79.3834C37.5427 80.2399 38.2103 81.0661 39.3505 81.7251C42.1507 83.3432 46.6905 83.3432 49.4905 81.7251C50.6307 81.0661 51.2984 80.2399 51.5101 79.3834C51.2984 78.5269 50.6307 77.7007 49.4905 77.0417Z"
                    fill="#F7CFD5"/>
                <path
                    d="M38.5407 92.5583C41.3407 90.9401 45.8806 90.9401 48.6808 92.5583C49.821 93.2173 50.4886 94.0434 50.7003 94.8999C51.0083 93.653 50.3408 92.341 48.6808 91.3816C45.8806 89.7634 41.3407 89.7634 38.5407 91.3816C36.8807 92.341 36.2131 93.653 36.5211 94.8999C36.7328 94.0434 37.4006 93.2173 38.5407 92.5583Z"
                    fill="#9954F2"/>
                <path
                    d="M38.5401 97.2407C41.3401 98.859 45.88 98.859 48.6802 97.2407C49.8203 96.5819 50.488 95.7556 50.6997 94.899C50.488 94.0425 49.8203 93.2164 48.6802 92.5574C45.88 90.9392 41.3401 90.9392 38.5401 92.5574C37.3999 93.2164 36.7322 94.0425 36.5205 94.899C36.7322 95.7556 37.3999 96.5819 38.5401 97.2407Z"
                    fill="#BB9AF4"/>
                <path
                    d="M50.3502 86.9077C50.5621 86.0511 51.2297 85.225 52.3699 84.5661C55.1699 82.9479 59.7098 82.9479 62.5099 84.5661C63.6501 85.225 64.3177 86.0511 64.5294 86.9077C64.8374 85.6607 64.1699 84.3486 62.5099 83.3894C59.7098 81.7712 55.1699 81.7712 52.3699 83.3894C50.7099 84.3486 50.0422 85.6607 50.3502 86.9077Z"
                    fill="#D06E0B"/>
                <path
                    d="M62.5103 84.5652C59.7101 82.947 55.1703 82.947 52.3703 84.5652C51.23 85.2241 50.5625 86.0502 50.3506 86.9068C50.5625 87.7635 51.23 88.5895 52.3703 89.2485C55.1703 90.8668 59.7101 90.8668 62.5103 89.2485C63.6504 88.5895 64.3181 87.7635 64.5297 86.9068C64.3181 86.0502 63.6504 85.2241 62.5103 84.5652Z"
                    fill="#E9A35D"/>
                <path
                    d="M61.7001 98.905C58.9001 97.2869 54.3603 97.2869 51.5601 98.905C49.9003 99.8644 49.2326 101.177 49.5407 102.423C49.7523 101.567 50.42 100.741 51.5601 100.082C54.3603 98.4635 58.9001 98.4635 61.7001 100.082C62.8404 100.741 63.5082 101.567 63.7198 102.423C64.0279 101.177 63.3601 99.8644 61.7001 98.905Z"
                    fill="#57A003"/>
                <path
                    d="M61.6995 100.081C58.8995 98.4626 54.3597 98.4626 51.5595 100.081C50.4194 100.74 49.7517 101.566 49.54 102.423C49.7517 103.279 50.4194 104.105 51.5595 104.764C54.3597 106.382 58.8995 106.382 61.6995 104.764C62.8398 104.105 63.5075 103.279 63.7192 102.423C63.5075 101.566 62.8398 100.74 61.6995 100.081Z"
                    fill="#8DC95E"/>
                <path
                    d="M65.3892 92.0896C68.1895 90.4714 72.7293 90.4714 75.5293 92.0896C76.6695 92.7485 77.3373 93.5746 77.5489 94.4313C77.857 93.1841 77.1893 91.8721 75.5293 90.9128C72.7293 89.2947 68.1895 89.2947 65.3892 90.9128C63.7293 91.8721 63.0617 93.1841 63.3698 94.4313C63.5814 93.5746 64.2492 92.7485 65.3892 92.0896Z"
                    fill="#E9A35D"/>
                <path
                    d="M65.3896 96.772C68.1898 98.3902 72.7297 98.3902 75.5297 96.772C76.6698 96.113 77.3376 95.2869 77.5493 94.4304C77.3376 93.5736 76.6698 92.7476 75.5297 92.0887C72.7297 90.4704 68.1898 90.4704 65.3896 92.0887C64.2496 92.7476 63.5818 93.5736 63.3701 94.4304C63.5818 95.2869 64.2496 96.113 65.3896 96.772Z"
                    fill="#F8D6B5"/>
                <path
                    d="M32.7132 104.487C31.5491 105.742 29.7709 106.328 28.0624 106.242C26.3541 106.155 24.718 105.448 23.3265 104.452C22.8688 104.124 22.4305 103.763 21.9281 103.51C20.6239 102.851 18.9391 103.039 17.8115 103.968C18.2719 102.757 19.3106 101.833 20.4746 101.265C21.6386 100.697 22.929 100.447 24.2091 100.251C25.9056 99.9914 27.6633 99.8206 29.3192 100.273C30.975 100.725 32.5237 101.921 32.9506 103.585C32.9905 103.741 33.0204 103.905 32.9819 104.06C32.9412 104.225 32.8286 104.362 32.7132 104.487Z"
                    fill="#2E3648"/>
                <path
                    d="M32.9268 102.549C33.2625 103.26 33.1716 104.361 32.7239 105.008C32.2761 105.655 31.641 105.603 31.3052 104.891C30.9695 104.179 31.0602 103.078 31.508 102.431C31.9557 101.784 32.5909 101.837 32.9268 102.549Z"
                    fill="white"/>
                <path d="M48.7921 107.746L31.8994 105.445L32.1644 101.986L49.3716 102.219L48.7921 107.746Z"
                      fill="white"/>
                <path
                    d="M50.4086 103.135C50.9426 104.266 50.7982 106.018 50.0861 107.046C49.3741 108.075 48.364 107.991 47.8301 106.86C47.2962 105.728 47.4406 103.977 48.1525 102.949C48.8647 101.92 49.8748 102.003 50.4086 103.135Z"
                    fill="#CDD3DF"/>
                <path d="M91.5803 109.839L49.0244 107.747L49.3718 102.219L91.5612 107.388L91.5803 109.839Z"
                      fill="#CDD3DF"/>
                <path
                    d="M92.1293 107.781C92.3661 108.283 92.3021 109.059 91.9862 109.515C91.6706 109.972 91.2225 109.935 90.9857 109.433C90.7489 108.931 90.813 108.154 91.1287 107.698C91.4445 107.242 91.8925 107.279 92.1293 107.781Z"
                    fill="#CDD3DF"/>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="135" height="135" fill="white"/>
                </clipPath>
              </defs>
            </svg>

            <!--доска с примером-->
            <svg v-if="type.id === 9" width="135" height="135" viewBox="0 0 135 135" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M90.3591 133.56L43.7275 106.221L43.7292 107.659L90.3639 135L125.737 114.393V112.955L90.3591 133.56Z"
                  fill="#8DC95E"/>
              <path d="M43.7275 106.221L90.3591 133.559L125.737 112.955L79.1026 85.6094L43.7275 106.221Z"
                    fill="#C7E6A8"/>
              <path d="M62.8125 104.965L77.0634 96.7031L79.4993 98.1246L65.2484 106.386L62.8125 104.965Z"
                    fill="#57A003"/>
              <path d="M64.0156 109.631L85.0855 97.416L85.7596 97.8093L64.6896 110.024L64.0156 109.631Z"
                    fill="#57A003"/>
              <path d="M66.4639 111.061L87.5338 98.8457L88.2078 99.239L67.1379 111.454L66.4639 111.061Z"
                    fill="#57A003"/>
              <path d="M68.9131 112.49L89.983 100.275L90.657 100.669L69.5871 112.884L68.9131 112.49Z" fill="#57A003"/>
              <path d="M71.3613 113.918L92.4313 101.703L93.1035 102.095L72.0336 114.31L71.3613 113.918Z"
                    fill="#57A003"/>
              <path d="M73.8096 115.348L94.8795 103.133L95.5517 103.525L74.4818 115.74L73.8096 115.348Z"
                    fill="#57A003"/>
              <path d="M87.7328 5.84375V65.4899L13.4004 109.261V49.6166L87.7328 5.84375Z" fill="#495469"/>
              <path d="M90.2567 6.43895L86.3066 4.13477L86.4609 64.8325L90.4074 67.1349L90.2567 6.43895Z"
                    fill="#643302"/>
              <path d="M90.4075 67.1364L86.461 64.834L11.9941 108.001L15.9439 110.305L90.4075 67.1364Z" fill="#9E4F00"/>
              <path d="M13.4001 48.2412L9.4502 45.9355L9.61741 112.138L13.5641 114.441L13.4001 48.2412Z"
                    fill="#643302"/>
              <path d="M92.6332 2.30583L88.6833 0L9.4502 45.9354L13.4001 48.2411L92.6332 2.30583Z" fill="#9E4F00"/>
              <path
                  d="M92.8006 68.5077L13.5643 114.441L13.4004 48.2419L92.6335 2.30664L92.8006 68.5077ZM15.944 110.306L90.4076 67.1367L90.2569 6.44066L15.7934 49.6111L15.944 110.306Z"
                  fill="#D06E0B"/>
              <path
                  d="M91.1703 119.718C91.169 120.102 91.0618 120.394 90.88 120.577L90.549 120.769L71.095 132.047L68.8965 128.401L88.5668 116.997C88.579 116.99 88.5912 116.983 88.6034 116.976C88.8768 116.828 89.2499 116.855 89.661 117.094C89.697 117.114 89.7322 117.136 89.7675 117.16C90.5117 117.651 91.1059 118.676 91.1649 119.567C91.169 119.618 91.1703 119.668 91.1703 119.718Z"
                  fill="#3D75E4"/>
              <path
                  d="M70.0021 128.485C69.1705 128.003 68.4939 128.389 68.4912 129.349C68.4885 130.308 69.1607 131.477 69.9923 131.959C70.8239 132.442 71.4999 132.054 71.5026 131.095C71.5053 130.135 70.8337 128.967 70.0021 128.485Z"
                  fill="#F8D6B5"/>
              <path
                  d="M71.0896 132.049C71.0896 132.049 69.1292 132.559 67.4319 132.904C66.103 133.174 64.9356 133.343 64.9933 133.088C65.0448 132.86 65.6404 132.102 66.3432 131.267C67.4265 129.981 68.7642 128.512 68.7642 128.512C71.3725 129.226 71.0896 132.049 71.0896 132.049Z"
                  fill="#F8D6B5"/>
              <path
                  d="M67.4319 132.904C66.103 133.174 64.9356 133.344 64.9933 133.089C65.0448 132.861 65.6404 132.103 66.3432 131.268C66.7671 131.436 67.3573 131.857 67.4319 132.904Z"
                  fill="#495469"/>
              <path
                  d="M90.2256 116.032C90.2256 116.032 92.3862 115.217 93.2984 116.503C93.9427 117.412 93.2121 119.225 92.2864 119.761L90.2256 116.032Z"
                  fill="#F196A5"/>
              <path
                  d="M92.7781 118.764C92.7768 119.272 92.5875 119.618 92.2863 119.762C92.2843 119.763 92.2829 119.764 92.2809 119.765L90.8801 120.577C91.0619 120.394 91.1691 120.102 91.1704 119.718C91.1704 119.669 91.1691 119.618 91.165 119.567C91.106 118.676 90.5117 117.651 89.7676 117.161C89.7323 117.137 89.697 117.114 89.6611 117.094C89.25 116.856 88.8769 116.828 88.6035 116.977L90.1468 116.082C90.4242 115.884 90.8272 115.891 91.2756 116.151C92.1086 116.633 92.7808 117.803 92.7781 118.764Z"
                  fill="#E4EDFB"/>
              <path
                  d="M70.3736 128.754C70.3736 128.754 70.0787 130.193 71.408 130.428L91.0889 119.098C91.0273 118.865 90.9222 118.561 90.7364 118.235C90.5768 117.955 90.4025 117.737 90.254 117.576C83.6272 121.302 77.0004 125.028 70.3736 128.754Z"
                  fill="#214EB0"/>
              <path
                  d="M41.5588 50.1875C41.3128 50.3301 41.1022 50.5741 40.9158 50.9533L39.7718 53.2954L38.6422 52.2714C38.4771 52.1091 38.2544 52.1032 38.0083 52.2458C37.5751 52.497 37.2361 53.0866 37.2374 53.5733C37.238 53.7761 37.3088 53.9108 37.4968 54.1004L38.7677 55.2457L37.3672 58.0758C37.2044 58.4139 37.1224 58.6778 37.1231 58.8938C37.1244 59.3805 37.4656 59.5758 37.8989 59.3246C38.145 59.1819 38.3678 58.9034 38.5428 58.545L39.7681 56.0197L41.0036 57.1184C41.1915 57.2664 41.4028 57.2932 41.6603 57.1439C42.0945 56.8923 42.4212 56.3098 42.4198 55.823C42.4192 55.6071 42.3483 55.4318 42.1833 55.2838L40.7837 54.0638L42.0668 51.451C42.2542 51.0844 42.3238 50.8277 42.3233 50.6249C42.3219 50.1381 41.9807 49.9429 41.5588 50.1875Z"
                  fill="#F6F9FE"/>
              <path
                  d="M44.8232 45.3331C44.0103 45.8044 43.3704 46.7676 43.2497 47.6663C43.229 47.8276 43.2233 47.9731 43.2367 48.0679C43.2587 48.364 43.45 48.4653 43.6961 48.3227C43.9351 48.1841 44.1192 47.8966 44.1527 47.5928C44.1586 47.5182 44.1724 47.431 44.1793 47.3478C44.2256 46.9188 44.4639 46.5277 44.7988 46.3336C45.1749 46.1155 45.4209 46.2735 45.4222 46.7379C45.4234 47.1638 45.2538 47.578 44.7499 48.3669L43.3739 50.5144C43.2442 50.7166 43.1831 50.9023 43.1836 51.0676C43.1844 51.3586 43.3907 51.4757 43.643 51.3294L46.0949 49.9008C46.355 49.75 46.5452 49.4265 46.5443 49.1345C46.5435 48.8506 46.3522 48.7492 46.0921 48.9L44.6648 49.7274L45.3046 48.7329C46.0812 47.5321 46.374 46.8098 46.3721 46.1324C46.3694 45.1549 45.7179 44.8145 44.8232 45.3331Z"
                  fill="#F6F9FE"/>
              <path
                  d="M50.151 44.4606C49.7177 44.7118 49.4024 45.2734 49.4038 45.7733L49.4083 47.342L47.9784 48.171C47.51 48.4425 47.2527 48.9716 47.2539 49.4178C47.2551 49.8497 47.5146 50.0924 47.983 49.8208L49.4129 48.9919L49.4173 50.5464C49.4188 51.0605 49.7362 51.2553 50.1695 51.0041C50.6027 50.7529 50.9304 50.1841 50.9289 49.67L50.9245 48.1155L52.3307 47.3003C52.7877 47.0354 53.0441 46.4937 53.0428 46.0617C53.0416 45.6155 52.783 45.3856 52.326 45.6505L50.9199 46.4657L50.9154 44.897C50.914 44.3971 50.5843 44.2094 50.151 44.4606Z"
                  fill="#F6F9FE"/>
              <path
                  d="M56.2859 47.3227C55.7709 47.6212 55.4537 47.5207 55.4524 47.0745C55.4512 46.6284 55.7318 46.2352 56.259 45.9295L57.5009 45.2095L57.5011 45.2907C57.5032 46.0481 56.9775 46.9217 56.2859 47.3227ZM56.5634 41.4871C55.7197 41.9762 54.9586 42.7151 54.468 43.5287C54.2577 43.8801 54.1415 44.1913 54.1422 44.4488C54.1434 44.8544 54.437 45.0225 54.8236 44.7983C54.9757 44.7102 55.1047 44.5805 55.2915 44.3107C55.6306 43.7749 55.9931 43.4022 56.4035 43.1643C57.1066 42.7567 57.4945 42.9655 57.4966 43.7088L57.4975 44.0059L56.0447 44.8481C54.6852 45.6363 53.855 46.863 53.8584 48.0667C53.8618 49.2561 54.6726 49.5986 55.8678 48.9057C56.5595 48.5047 57.1449 47.9084 57.5655 47.2035C57.6369 47.5683 57.9418 47.6901 58.3285 47.466C58.7627 47.2143 59.0894 46.645 59.0879 46.1177L59.0788 42.9002C59.0737 41.0749 58.1568 40.5634 56.5634 41.4871Z"
                  fill="#F6F9FE"/>
              <path
                  d="M64.719 36.7597C64.4729 36.9024 64.2623 37.1464 64.0759 37.5256L62.932 39.8677L61.8024 38.8437C61.6373 38.6814 61.4146 38.6754 61.1685 38.8181C60.7352 39.0693 60.3962 39.6589 60.3976 40.1456C60.3982 40.3484 60.469 40.4831 60.657 40.6727L61.9278 41.818L60.5274 44.6481C60.3637 44.9867 60.2826 45.2501 60.2832 45.466C60.2846 45.9528 60.6258 46.148 61.0591 45.8968C61.3052 45.7542 61.528 45.4757 61.703 45.1173L62.9283 42.5919L64.1637 43.6907C64.3517 43.8387 64.563 43.8655 64.8205 43.7162C65.2547 43.4645 65.5814 42.8821 65.58 42.3953C65.5794 42.1793 65.5085 42.0041 65.3434 41.856L63.9439 40.636L65.227 38.0233C65.4134 37.6572 65.484 37.4 65.4834 37.1972C65.4821 36.7104 65.1408 36.5152 64.719 36.7597Z"
                  fill="#F6F9FE"/>
              <path
                  d="M26.8224 73.9744C26.6932 74.0493 26.5642 74.1779 26.4124 74.3745C25.1619 76.0471 24.3349 78.3955 24.3428 81.2084C24.3507 84.0071 25.1883 85.3904 26.4442 85.6099C26.5965 85.6435 26.7258 85.6091 26.855 85.5342C27.1951 85.3371 27.4517 84.8633 27.4507 84.4983C27.4501 84.2823 27.344 84.1264 27.1792 84.0595C26.275 83.6898 25.7315 82.6104 25.7252 80.4069C25.719 78.1892 26.2547 76.4973 27.1538 75.0691C27.3177 74.8116 27.4226 74.5476 27.422 74.3174C27.421 73.9656 27.1625 73.7772 26.8224 73.9744Z"
                  fill="#F6F9FE"/>
              <path
                  d="M32.7132 73.6054C32.4672 73.7481 32.2566 73.992 32.0701 74.3713L30.9262 76.7134L29.7966 75.6894C29.6324 75.5266 29.4088 75.5211 29.1627 75.6638C28.7295 75.915 28.3904 76.5046 28.3918 76.9913C28.3924 77.1941 28.4632 77.3288 28.6521 77.5168L29.9229 78.6632L28.5225 81.4922C28.3588 81.8309 28.2777 82.0952 28.2783 82.3112C28.2797 82.798 28.62 82.9937 29.0542 82.742C29.3003 82.5994 29.5222 82.3214 29.6972 81.963L30.9225 79.4376L32.158 80.5364C32.3459 80.6844 32.5572 80.7112 32.8156 80.5614C33.2489 80.3102 33.5756 79.7278 33.5742 79.241C33.5736 79.025 33.5027 78.8488 33.3385 78.7002L31.9381 77.4807L33.2221 74.8675C33.4085 74.5024 33.4782 74.2457 33.4777 74.0429C33.4763 73.5561 33.1351 73.3609 32.7132 73.6054Z"
                  fill="#F6F9FE"/>
              <path
                  d="M37.0817 70.3278C36.6475 70.5795 36.3331 71.1406 36.3345 71.6406L36.3389 73.2092L34.909 74.0382C34.4398 74.3102 34.1833 74.8378 34.1846 75.2839C34.1858 75.7169 34.4444 75.9601 34.9137 75.688L36.3436 74.8591L36.348 76.4135C36.3494 76.9277 36.666 77.123 37.1001 76.8713C37.5334 76.6201 37.861 76.0513 37.8596 75.5372L37.8552 73.9827L39.2613 73.1675C39.7183 72.9026 39.9747 72.3609 39.9735 71.9279C39.9722 71.4817 39.7137 71.2528 39.2567 71.5177L37.8505 72.3329L37.8461 70.7642C37.8447 70.2643 37.5149 70.0766 37.0817 70.3278Z"
                  fill="#F6F9FE"/>
              <path
                  d="M43.2165 73.1916C42.7007 73.4907 42.3835 73.3902 42.3822 72.944C42.381 72.4979 42.6615 72.1046 43.1888 71.7989L44.4315 71.0785L44.4317 71.1596C44.4339 71.9171 43.9082 72.7906 43.2165 73.1916ZM43.4932 67.3566C42.6495 67.8457 41.8893 68.5841 41.3986 69.3967C41.1883 69.7491 41.0713 70.0608 41.072 70.3173C41.0731 70.7229 41.3676 70.8914 41.7543 70.6672C41.9064 70.5791 42.0354 70.4495 42.2221 70.1787C42.5613 69.6439 42.9238 69.2712 43.3342 69.0333C44.0373 68.6257 44.4252 68.8345 44.4273 69.5778L44.4281 69.8749L42.9745 70.7176C41.6158 71.5052 40.7857 72.732 40.7891 73.9347C40.7924 75.1251 41.6033 75.4676 42.7985 74.7747C43.4901 74.3737 44.0747 73.7768 44.4953 73.0729C44.5667 73.4378 44.8725 73.5581 45.2592 73.3339C45.6925 73.0827 46.0201 72.514 46.0186 71.9867L46.0095 68.7691C46.0043 66.9439 45.0874 66.4324 43.4932 67.3566Z"
                  fill="#F6F9FE"/>
              <path
                  d="M47.7041 61.9087C47.364 62.1058 47.1074 62.5928 47.1084 62.9447C47.109 63.1749 47.2151 63.3165 47.379 63.384C48.2841 63.7663 48.8268 64.833 48.833 67.0507C48.8392 69.2542 48.3044 70.9588 47.4044 72.3744C47.2414 72.6314 47.1365 72.9095 47.1371 73.1255C47.1382 73.4906 47.3966 73.6658 47.7367 73.4686C47.865 73.3942 47.9941 73.2787 48.1458 73.0689C49.3972 71.3958 50.2242 69.0475 50.2163 66.2488C50.2084 63.4358 49.3708 62.0525 48.1141 61.8335C47.9617 61.8131 47.8324 61.8343 47.7041 61.9087Z"
                  fill="#F6F9FE"/>
              <path
                  d="M54.3136 58.0366C54.1844 58.1115 54.0554 58.2402 53.9037 58.4368C52.6522 60.1099 51.8261 62.4577 51.834 65.2707C51.8419 68.0694 52.6786 69.4532 53.9354 69.6722C54.0878 69.7058 54.2171 69.6714 54.3463 69.5965C54.6855 69.3999 54.9429 68.9256 54.9419 68.5605C54.9413 68.3446 54.8352 68.1887 54.6704 68.1217C53.7653 67.7526 53.2227 66.6727 53.2165 64.4692C53.2102 62.2515 53.745 60.5601 54.645 59.1314C54.8089 58.8738 54.9138 58.6099 54.9132 58.3797C54.9122 58.0278 54.6528 57.84 54.3136 58.0366Z"
                  fill="#F6F9FE"/>
              <path
                  d="M60.2034 57.6679C59.9573 57.8106 59.7467 58.0545 59.5603 58.4338L58.4164 60.7759L57.2867 59.7519C57.1225 59.5891 56.8989 59.5836 56.6529 59.7263C56.2196 59.9775 55.8806 60.5671 55.882 61.0538C55.8825 61.2566 55.9533 61.3913 56.1422 61.5793L57.4122 62.7262L56.0126 65.5547C55.849 65.8934 55.767 66.1583 55.7676 66.3742C55.769 66.861 56.1102 67.0562 56.5434 66.805C56.7895 66.6624 57.0123 66.3839 57.1874 66.0255L58.4126 63.5001L59.6481 64.5989C59.836 64.7469 60.0474 64.7737 60.3057 64.6239C60.739 64.3727 61.0657 63.7903 61.0644 63.3035C61.0637 63.0875 60.9928 62.9113 60.8278 62.7632L59.4282 61.5432L60.7113 58.9305C60.8987 58.5649 60.9684 58.3082 60.9678 58.1054C60.9664 57.6186 60.6252 57.4234 60.2034 57.6679Z"
                  fill="#F6F9FE"/>
              <path
                  d="M64.5729 54.3902C64.1387 54.6419 63.8234 55.2035 63.8248 55.7035L63.8293 57.2721L62.4003 58.1006C61.931 58.3726 61.6745 58.9002 61.6758 59.3464C61.677 59.7793 61.9356 60.0225 62.4049 59.7504L63.8339 58.922L63.8383 60.4765C63.8398 60.9906 64.1572 61.1854 64.5914 60.9337C65.0246 60.6825 65.3514 60.1143 65.3499 59.6001L65.3455 58.0456L66.7517 57.2304C67.2087 56.9655 67.4659 56.4233 67.4647 55.9903C67.4635 55.5441 67.204 55.3157 66.747 55.5806L65.3409 56.3958L65.3364 54.8271C65.335 54.3272 65.0062 54.139 64.5729 54.3902Z"
                  fill="#F6F9FE"/>
              <path
                  d="M71.6675 56.3723C70.7887 56.8818 70.2013 56.4768 70.1982 55.3817C70.1951 54.2865 70.7906 53.1836 71.6571 52.6812C72.5245 52.1784 73.1595 52.5954 73.1624 53.65C73.1655 54.7319 72.5463 55.8628 71.6675 56.3723ZM69.4166 48.8611C68.9596 49.126 68.6206 49.7288 68.6221 50.2561L68.6464 58.8551C68.6478 59.3824 68.9891 59.5908 69.4347 59.3325C69.8442 59.0951 70.1596 58.5598 70.1933 58.0395C70.5939 58.3486 71.2152 58.3002 71.9649 57.8656C73.5942 56.921 74.7739 54.7604 74.7681 52.7323C74.7624 50.7042 73.5744 49.9172 71.9451 50.8618C71.2192 51.2826 70.611 51.9328 70.2134 52.665L70.204 49.339C70.2025 48.7975 69.8621 48.6028 69.4166 48.8611Z"
                  fill="#F6F9FE"/>
              <path
                  d="M75.9453 45.5359C75.6052 45.7331 75.3486 46.2201 75.3496 46.5719C75.3503 46.8021 75.4563 46.9438 75.6211 47.0107C76.5253 47.3935 77.0689 48.4598 77.0751 50.6774C77.0813 52.8809 76.5457 54.586 75.6465 56.0011C75.4826 56.2586 75.3777 56.5368 75.3784 56.7527C75.3794 57.1178 75.6378 57.293 75.9779 57.0958C76.1071 57.0209 76.2353 56.906 76.3879 56.6957C77.6384 55.023 78.4654 52.6747 78.4575 49.876C78.4496 47.0631 77.612 45.6798 76.3561 45.4602C76.2029 45.4404 76.0745 45.461 75.9453 45.5359Z"
                  fill="#F6F9FE"/>
              <path
                  d="M78.8109 38.2768L23.5837 70.294C23.3754 70.4147 23.2064 70.7088 23.207 70.9491L23.2095 71.8221C23.2102 72.0625 23.3803 72.1599 23.5886 72.0391L78.8158 40.0219C79.025 39.9007 79.194 39.6066 79.1933 39.3663L79.1909 38.4932C79.1902 38.2529 79.0201 38.1555 78.8109 38.2768Z"
                  fill="#F6F9FE"/>
            </svg>

            <!--книги и лестница-->
            <svg v-if="[11,13,16].includes(type.id)" width="140" height="152" viewBox="0 0 140 152" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M84.6023 78.1197C82.4431 76.895 80.396 76.2605 78.5422 76.1376C78.5436 76.0109 78.5594 75.8959 78.5594 75.7677C78.5594 57.7482 65.6012 35.7906 49.6166 26.7242C36.7626 19.4335 25.8805 22.7157 22.1051 33.6451C21.1187 32.9076 20.1144 32.2203 19.0836 31.6357C8.54425 25.6579 0.000541687 30.4435 0.000541687 42.3245C0.000541687 54.2054 8.54425 68.683 19.0836 74.6609C24.3517 77.6489 78.3654 108.285 84.6023 111.823C92.8582 116.505 99.5508 112.757 99.5508 103.45C99.5508 94.1432 92.8582 82.8024 84.6023 78.1197Z"
                  fill="#C0D6F6"/>
              <path
                  d="M124.929 55.2954C122.77 54.0707 120.723 53.4362 118.869 53.3134C118.871 53.1866 118.886 53.0717 118.886 52.9435C118.886 34.9239 105.928 12.9664 89.9437 3.90005C77.0896 -3.39073 66.2075 -0.108579 62.4323 10.8208C61.4458 10.0834 60.4414 9.39614 59.4108 8.81155C48.8714 2.83366 40.3276 7.61934 40.3276 19.5002C40.3276 31.3812 48.8714 45.8587 59.4108 51.8366C64.6787 54.8246 118.693 85.4608 124.929 88.9984C133.185 93.681 139.878 89.9322 139.878 80.6256C139.878 71.3189 133.185 59.978 124.929 55.2954Z"
                  fill="#F6F9FE"/>
              <path
                  d="M26.1642 33.4026V71.9013L47.5194 59.808C47.9219 59.58 48.1699 59.1584 48.1699 58.7019V22.277C48.1699 21.683 47.5156 21.3115 46.9917 21.6083L26.1642 33.4026Z"
                  fill="#E14761"/>
              <path d="M26.6253 34.3358L28.8436 36.5853L50.7412 23.9512L47.9978 22.1094L26.6253 34.3358Z" fill="white"/>
              <path
                  d="M29.4446 35.5178V74.0165L50.7997 61.9232C51.2022 61.6952 51.4502 61.2736 51.4502 60.8171V24.3922C51.4502 23.7982 50.7959 23.4268 50.272 23.7235L29.4446 35.5178Z"
                  fill="#F7CFD5"/>
              <path
                  d="M29.4648 35.5186V74.0173C29.4648 74.0173 27.2067 73.8941 26.1779 71.8624L26.1653 71.8377V33.3613C27.195 35.3972 29.4648 35.5186 29.4648 35.5186Z"
                  fill="#F196A5"/>
              <path
                  d="M31.7844 36.1663V74.6649L53.1395 62.5717C53.542 62.3438 53.79 61.922 53.79 61.4656V25.0407C53.79 24.4466 53.1357 24.0752 52.612 24.3719L31.7844 36.1663Z"
                  fill="#57A003"/>
              <path d="M31.9896 37.2181L36.5104 42.0296L60.249 28.9298L53.4273 25.0605L31.9896 37.2181Z" fill="white"/>
              <path
                  d="M39.1417 40.3088V78.8074L60.4969 66.7143C60.8994 66.4863 61.1475 66.0645 61.1475 65.6081V29.1832C61.1475 28.5892 60.4931 28.2178 59.9693 28.5145L39.1417 40.3088Z"
                  fill="#C7E6A8"/>
              <path
                  d="M39.1621 40.3098V78.8083L37.0593 78.4601C34.7818 78.0829 32.8255 76.6577 31.7967 74.6261L31.7841 74.6013V36.125C32.8138 38.1608 34.7746 39.5883 37.0569 39.9636L39.1621 40.3098Z"
                  fill="#8DC95E"/>
              <path
                  d="M40.3302 41.8967V80.3953L61.6854 68.3021C62.0879 68.0741 62.3359 67.6524 62.3359 67.1959V30.7711C62.3359 30.1771 61.6816 29.8057 61.1578 30.1024L40.3302 41.8967Z"
                  fill="#113079"/>
              <path d="M40.5406 42.9636L44.4963 45.7394L65.4131 33.7823L61.0326 31.3457L40.5406 42.9636Z" fill="white"/>
              <path
                  d="M45.2462 44.6058V83.1043L66.6014 71.0112C67.0039 70.7832 67.252 70.3614 67.252 69.905V33.4801C67.252 32.8861 66.5976 32.5147 66.0738 32.8113L45.2462 44.6058Z"
                  fill="#3D75E4"/>
              <path
                  d="M45.2646 44.6076V83.1061C45.2646 83.1061 41.3693 82.3881 40.3405 80.3565L40.3279 80.3317V41.8555C41.3576 43.8914 45.2646 44.6076 45.2646 44.6076Z"
                  fill="#214EB0"/>
              <path
                  d="M56.2297 48.6278C56.3949 48.5341 56.5604 48.5376 56.6459 48.6837L58.1806 51.3067C58.2487 51.4224 58.3792 51.4556 58.5303 51.3948L61.9413 50.0201C62.3213 49.8669 62.4745 50.3081 62.2006 50.7663L59.7414 54.8806C59.6323 55.0627 59.5832 55.2638 59.6094 55.419L60.2028 58.922C60.2553 59.2306 60.0183 59.6399 59.7483 59.793C59.6771 59.8334 59.6035 59.8559 59.5325 59.8541L56.4776 59.7741C56.41 59.7723 56.3359 59.7942 56.2619 59.8362C56.1878 59.8782 56.1139 59.9403 56.0465 60.0186L53.0024 63.5579C52.9315 63.6403 52.8581 63.7011 52.7868 63.7415C52.5169 63.8946 52.2782 63.7552 52.3285 63.3882L52.9001 59.2244C52.9254 59.0401 52.8749 58.8955 52.7654 58.8373L50.2906 57.5217C50.0146 57.3752 50.1648 56.7621 50.5444 56.4843L53.9525 53.9913C54.1031 53.881 54.2334 53.6998 54.3005 53.5075L55.8152 49.1548C55.8996 48.9124 56.0645 48.7215 56.2297 48.6278Z"
                  fill="#F6F9FE"/>
              <path opacity="0.5"
                    d="M139.878 80.5713C139.878 71.2646 133.185 59.9238 124.929 55.2411C122.77 54.0164 120.723 53.3819 118.869 53.2591C118.871 53.1323 118.886 53.0174 118.886 52.8892C118.886 34.8697 105.928 12.9121 89.9438 3.84577C80.9687 -1.2448 73.1549 -0.562898 67.6543 3.04726L58.4225 8.22524C53.2051 5.566 48.5416 5.5839 45.2558 7.81478L6.45815 29.8722C9.82277 28.4302 14.2408 28.899 19.0831 31.6456C20.1138 32.2302 21.1181 32.9175 22.1046 33.6549C25.8799 22.7255 36.762 19.4433 49.6161 26.7341C65.6007 35.8004 78.5588 57.758 78.5588 75.7775C78.5588 75.9057 78.5429 76.0206 78.5418 76.1474C80.3954 76.2702 82.4426 76.9047 84.6018 78.1294C92.8576 82.8121 99.5502 94.1529 99.5502 103.46C99.5502 107.397 98.3487 110.335 96.3409 112.065L135.032 90.2755L135.017 90.2489C138.004 88.8621 139.878 85.4952 139.878 80.5713Z"
                    fill="#C0D6F6"/>
              <path d="M55.3904 140.722L57.7662 140.199L59.7725 131.766L57.3966 132.288L55.3904 140.722Z"
                    fill="#9E4F00"/>
              <path d="M58.0788 129.426L60.4546 128.903L64.5059 111.873L62.1301 112.396L58.0788 129.426Z"
                    fill="#9E4F00"/>
              <path d="M75.5617 139.342L77.9375 138.819L60.4533 128.902L58.0775 129.425L75.5617 139.342Z"
                    fill="#D06E0B"/>
              <path d="M62.811 109.533L65.1868 109.011L69.2383 91.9805L66.8625 92.5032L62.811 109.533Z" fill="#9E4F00"/>
              <path d="M80.2943 119.451L82.6699 118.929L65.1857 109.012L62.8099 109.534L80.2943 119.451Z"
                    fill="#D06E0B"/>
              <path d="M67.5436 89.6398L69.9194 89.1171L73.9766 72.0625L71.6008 72.5854L67.5436 89.6398Z"
                    fill="#9E4F00"/>
              <path d="M72.2814 69.723L74.6572 69.2003L77.4619 57.4102L75.0863 57.9329L72.2814 69.723Z" fill="#9E4F00"/>
              <path d="M75.0863 57.9331L77.4619 57.4104L75.0479 56.041L72.6721 56.5637L75.0863 57.9331Z"
                    fill="#D06E0B"/>
              <path d="M85.0277 99.5589L87.4033 99.0362L69.9191 89.1191L67.5433 89.6419L85.0277 99.5589Z"
                    fill="#D06E0B"/>
              <path d="M89.766 79.639L92.1416 79.1162L74.6573 69.1992L72.2815 69.7219L89.766 79.639Z" fill="#D06E0B"/>
              <path d="M94.965 69.2092L97.3408 68.6865L94.9461 67.3281L92.5703 67.851L94.965 69.2092Z" fill="#D06E0B"/>
              <path d="M75.2701 151.995L77.6458 151.473L97.3408 68.6836L94.965 69.2063L75.2701 151.995Z"
                    fill="#9E4F00"/>
              <path
                  d="M72.8761 150.642L74.8823 142.208L57.398 132.291L55.3918 140.725L52.9776 139.356L72.6726 56.5664L75.0868 57.9358L72.282 69.7259L89.7664 79.643L92.5711 67.8528L94.9658 69.211L75.2709 152L72.8761 150.642ZM89.0853 82.5057L71.6009 72.5888L67.5437 89.6433L85.0281 99.5603L89.0853 82.5057ZM84.3471 102.423L66.8628 92.5057L62.8114 109.536L80.2958 119.453L84.3471 102.423ZM62.1303 112.399L58.0791 129.429L75.5634 139.346L79.6148 122.316L62.1303 112.399Z"
                  fill="#E9A35D"/>
            </svg>

            <!--компьютер-->
            <svg v-if="[14,17].includes(type.id)" width="175" height="152" viewBox="0 0 175 152" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M145.748 60.1962C145.748 60.1777 145.748 60.1592 145.743 60.1407C145.725 59.8954 145.697 59.6547 145.656 59.4141C145.656 59.4048 145.651 59.3955 145.651 59.3909C145.61 59.1503 145.554 58.9096 145.489 58.6736V58.6689C145.42 58.4329 145.342 58.1969 145.249 57.9701C145.245 57.9655 145.245 57.9562 145.24 57.9516C145.078 57.5489 144.88 57.1648 144.649 56.8131C144.649 56.8084 144.644 56.8084 144.644 56.8038C144.616 56.7622 144.589 56.7251 144.561 56.6835C144.506 56.6048 144.45 56.5215 144.39 56.4475C144.381 56.4336 144.367 56.4197 144.358 56.4058C144.275 56.304 144.196 56.2068 144.108 56.1096C144.081 56.0818 144.053 56.0587 144.03 56.0309C143.956 55.9523 143.882 55.8782 143.803 55.8088C143.757 55.7671 143.702 55.7301 143.656 55.6885C143.596 55.6375 143.536 55.5866 143.475 55.5404C143.36 55.4571 143.245 55.3784 143.124 55.309L74.4984 15.6232C73.6993 15.1604 72.891 15.091 72.175 15.3363L72.1797 15.327L72.0549 15.3733C72.0457 15.378 72.0365 15.3826 72.0226 15.3826L69.5422 16.3313L71.7686 17.4236C71.7593 17.4652 71.7455 17.5022 71.7362 17.5439L64.3688 51.1391L60.558 68.5175C59.9991 71.0675 61.1169 74.048 63.0616 75.168L130.029 113.91L129.789 116.751L132.5 115.701C133.41 115.275 134.14 114.308 134.441 112.933L145.619 61.9595C145.743 61.3902 145.78 60.7932 145.748 60.1962Z"
                  fill="#A2ABBE"/>
              <path
                  d="M118.976 116.983L58.4291 81.9577C56.4891 80.833 53.0987 80.9765 50.8538 82.2724L7.95155 107.083L2.2793 106.334V109.559H2.28392C2.24696 110.305 2.67192 111.022 3.5865 111.55L72.2033 151.245C72.328 151.319 72.4619 151.383 72.5959 151.448C72.6328 151.467 72.6698 151.476 72.7067 151.494C72.8084 151.541 72.9146 151.582 73.0255 151.619C73.0624 151.633 73.104 151.643 73.1409 151.656C73.2518 151.693 73.3673 151.73 73.4874 151.758C73.5197 151.767 73.5567 151.772 73.589 151.781C73.5936 151.781 73.5982 151.786 73.6075 151.786C73.6167 151.786 73.6259 151.791 73.6352 151.791C73.746 151.818 73.8615 151.842 73.977 151.865C74.0001 151.869 74.0278 151.874 74.0555 151.879C74.1987 151.902 74.3465 151.925 74.4943 151.943C74.5082 151.943 74.5267 151.948 74.5405 151.948C74.7022 151.966 74.8639 151.98 75.0255 151.99C75.0302 151.99 75.0394 151.99 75.044 151.99C75.0948 151.994 75.1456 151.994 75.1918 151.994C75.2057 151.994 75.2195 151.994 75.2334 151.994C75.3443 151.999 75.4505 151.999 75.5614 151.999H75.566C75.7184 151.999 75.8708 151.994 76.0279 151.985C76.0556 151.985 76.0833 151.98 76.111 151.98C76.6376 151.943 77.1688 151.865 77.6861 151.74C77.7231 151.73 77.76 151.721 77.797 151.712C78.0418 151.652 78.282 151.578 78.5176 151.494C78.5545 151.481 78.5915 151.467 78.6284 151.453C78.8686 151.365 79.1042 151.272 79.3259 151.161C79.4783 151.087 79.6308 151.008 79.774 150.925L126.496 123.906C127.757 123.175 128.385 122.236 128.348 121.356V118.223L118.976 116.983Z"
                  fill="#A2ABBE"/>
              <path
                  d="M127.405 116.496L58.7887 76.8007C56.8487 75.676 55.7263 72.7002 56.2852 70.1501L67.4634 19.1766C68.0223 16.6265 70.0501 15.4695 71.9901 16.5895L140.607 56.2844C142.547 57.409 143.669 60.3849 143.11 62.935L131.932 113.909C131.373 116.463 129.346 117.62 127.405 116.496Z"
                  fill="#E6EBF5"/>
              <path
                  d="M72.2027 148.021L3.586 108.326C1.64598 107.202 1.88617 105.244 4.13105 103.943L50.8533 76.9247C53.0935 75.6289 56.4839 75.4854 58.4286 76.61L127.045 116.305C128.985 117.43 128.745 119.387 126.5 120.688L79.7781 147.706C77.5332 149.002 74.1428 149.146 72.2027 148.021Z"
                  fill="#E6EBF5"/>
              <path
                  d="M53.9661 130.476L32.5565 118.092C31.933 117.731 32.0115 117.101 32.7321 116.685L45.6517 109.21C46.3723 108.794 47.4578 108.748 48.0813 109.109L69.4909 121.493C70.1145 121.854 70.0359 122.484 69.3153 122.9L56.3957 130.375C55.6751 130.791 54.5897 130.837 53.9661 130.476Z"
                  fill="white"/>
              <path
                  d="M125.918 112.703L61.3844 75.3919C59.606 74.3645 58.5852 71.6386 59.0933 69.306L69.32 22.6597C69.8327 20.3272 71.685 19.2673 73.4633 20.2948L137.997 57.6063C139.775 58.6337 140.796 61.3596 140.288 63.6922L130.061 110.339C129.548 112.671 127.696 113.731 125.918 112.703Z"
                  fill="#3D75E4"/>
              <path
                  d="M32.7692 95.1846L29.6652 93.3889C29.4157 93.2454 29.4481 92.9909 29.7344 92.8243L33.3974 90.7046C33.6838 90.538 34.1226 90.5195 34.372 90.663L37.4761 92.4587C37.7255 92.6021 37.6932 92.8567 37.4068 93.0233L33.7438 95.1429C33.4528 95.3096 33.0186 95.3281 32.7692 95.1846Z"
                  fill="#CDD3DF"/>
              <path
                  d="M37.5641 97.958L34.4601 96.1623C34.2107 96.0188 34.243 95.7643 34.5294 95.5977L38.1923 93.478C38.4787 93.3114 38.9175 93.2929 39.1669 93.4364L42.271 95.2321C42.5204 95.3755 42.4881 95.6301 42.2017 95.7967L38.5387 97.9163C38.2477 98.0876 37.8135 98.1015 37.5641 97.958Z"
                  fill="#CDD3DF"/>
              <path
                  d="M42.3581 100.737L39.254 98.9416C39.0046 98.7982 39.0369 98.5436 39.3233 98.377L42.9863 96.2573C43.2726 96.0907 43.7114 96.0722 43.9609 96.2157L47.0649 98.0114C47.3143 98.1548 47.282 98.4094 46.9956 98.576L43.3327 100.696C43.0417 100.862 42.6075 100.881 42.3581 100.737Z"
                  fill="#CDD3DF"/>
              <path
                  d="M48.9909 98.4463L45.8868 96.6506C45.6374 96.5072 45.6697 96.2526 45.9561 96.086L49.0325 94.3042C49.3188 94.1376 49.7577 94.1191 50.0071 94.2626L53.1111 96.0583C53.3606 96.2017 53.3282 96.4563 53.0418 96.6229L49.9655 98.4047C49.6745 98.5713 49.2403 98.5898 48.9909 98.4463Z"
                  fill="#CDD3DF"/>
              <path
                  d="M53.7868 101.218L50.6827 99.4221C50.4333 99.2787 50.4656 99.0241 50.752 98.8575L53.8283 97.0757C54.1147 96.9091 54.5535 96.8906 54.803 97.034L57.907 98.8297C58.1564 98.9732 58.1241 99.2277 57.8377 99.3944L54.7614 101.176C54.4704 101.343 54.0362 101.361 53.7868 101.218Z"
                  fill="#CDD3DF"/>
              <path
                  d="M58.5797 103.989L55.4757 102.194C55.2263 102.05 55.2586 101.796 55.545 101.629L58.6213 99.8472C58.9077 99.6806 59.3465 99.6621 59.5959 99.8055L62.7 101.601C62.9494 101.745 62.9171 101.999 62.6307 102.166L59.5544 103.948C59.2634 104.114 58.8292 104.133 58.5797 103.989Z"
                  fill="#CDD3DF"/>
              <path
                  d="M63.3756 106.767L60.2716 104.971C60.0222 104.827 60.0545 104.573 60.3409 104.406L63.4172 102.625C63.7036 102.458 64.1424 102.439 64.3918 102.583L67.4959 104.379C67.7453 104.522 67.713 104.777 67.4266 104.943L64.3503 106.725C64.0593 106.892 63.6251 106.91 63.3756 106.767Z"
                  fill="#CDD3DF"/>
              <path
                  d="M68.1706 109.54L65.0665 107.744C64.8171 107.601 64.8494 107.346 65.1358 107.18L68.2121 105.398C68.4985 105.231 68.9373 105.213 69.1868 105.356L72.2908 107.152C72.5402 107.295 72.5079 107.55 72.2215 107.717L69.1452 109.498C68.8542 109.665 68.42 109.684 68.1706 109.54Z"
                  fill="#CDD3DF"/>
              <path
                  d="M72.9655 112.312L69.8615 110.516C69.612 110.372 69.6444 110.118 69.9307 109.951L73.0071 108.169C73.2934 108.003 73.7323 107.984 73.9817 108.128L77.0857 109.923C77.3352 110.067 77.3028 110.322 77.0164 110.488L73.9401 112.27C73.6491 112.437 73.2149 112.455 72.9655 112.312Z"
                  fill="#CDD3DF"/>
              <path
                  d="M77.7594 115.081L74.6554 113.285C74.406 113.142 74.4383 112.887 74.7247 112.721L77.801 110.939C78.0874 110.772 78.5262 110.754 78.7756 110.897L81.8797 112.693C82.1291 112.836 82.0968 113.091 81.8104 113.258L78.7341 115.039C78.4431 115.211 78.0089 115.229 77.7594 115.081Z"
                  fill="#CDD3DF"/>
              <path
                  d="M82.5544 117.86L79.4503 116.065C79.2009 115.921 79.2332 115.667 79.5196 115.5L82.5959 113.718C82.8823 113.552 83.3211 113.533 83.5706 113.677L86.6746 115.472C86.9241 115.616 86.8917 115.87 86.6053 116.037L83.529 117.819C83.238 117.985 82.8038 118.004 82.5544 117.86Z"
                  fill="#CDD3DF"/>
              <path
                  d="M87.3483 120.632L84.2443 118.836C83.9948 118.693 84.0272 118.438 84.3135 118.272L87.3899 116.49C87.6763 116.323 88.1151 116.305 88.3645 116.448L91.4685 118.244C91.718 118.387 91.6856 118.642 91.3993 118.808L88.3229 120.59C88.0319 120.757 87.5977 120.775 87.3483 120.632Z"
                  fill="#CDD3DF"/>
              <path
                  d="M92.1432 123.403L89.0392 121.608C88.7898 121.464 88.8221 121.21 89.1085 121.043L92.1848 119.261C92.4712 119.095 92.91 119.076 93.1594 119.22L96.2635 121.015C96.5129 121.159 96.4806 121.413 96.1942 121.58L93.1179 123.362C92.8269 123.533 92.3927 123.547 92.1432 123.403Z"
                  fill="#CDD3DF"/>
              <path
                  d="M56.0544 97.1299L52.9503 95.3342C52.7009 95.1908 52.7332 94.9362 53.0196 94.7696L56.0959 92.9878C56.3823 92.8212 56.8211 92.8027 57.0706 92.9462L60.1746 94.7418C60.424 94.8853 60.3917 95.1399 60.1053 95.3065L57.029 97.0883C56.738 97.2549 56.3038 97.2734 56.0544 97.1299Z"
                  fill="#CDD3DF"/>
              <path
                  d="M51.1911 94.3155L48.087 92.5198C47.8376 92.3763 47.8699 92.1218 48.1563 91.9552L51.2326 90.1734C51.519 90.0067 51.9578 89.9882 52.2073 90.1317L55.3113 91.9274C55.5607 92.0709 55.5284 92.3254 55.242 92.492L52.1657 94.2738C51.8747 94.4404 51.4359 94.4589 51.1911 94.3155Z"
                  fill="#CDD3DF"/>
              <path
                  d="M46.3126 91.4943L39.6241 87.6253C39.3747 87.4818 39.407 87.2272 39.6934 87.0606L42.7698 85.2788C43.0561 85.1122 43.495 85.0937 43.7444 85.2372L50.4329 89.1062C50.6823 89.2497 50.65 89.5043 50.3636 89.6709L47.2872 91.4527C47.0009 91.6239 46.562 91.6424 46.3126 91.4943Z"
                  fill="#CDD3DF"/>
              <path
                  d="M49.7215 88.0697L44.2941 84.9319C44.0446 84.7884 44.077 84.5339 44.3634 84.3673L47.4397 82.5855C47.7261 82.4189 48.1649 82.4003 48.4143 82.5438L53.8417 85.6817C54.0912 85.8251 54.0588 86.0797 53.7725 86.2463L50.6961 88.0281C50.4051 88.1947 49.9709 88.2132 49.7215 88.0697Z"
                  fill="#CDD3DF"/>
              <path
                  d="M60.8493 99.9014L57.7452 98.1057C57.4958 97.9622 57.5281 97.7077 57.8145 97.5411L60.8908 95.7593C61.1772 95.5927 61.616 95.5742 61.8655 95.7176L64.9695 97.5133C65.2189 97.6568 65.1866 97.9113 64.9002 98.0779L61.8239 99.8597C61.5329 100.026 61.0987 100.045 60.8493 99.9014Z"
                  fill="#CDD3DF"/>
              <path
                  d="M65.6432 102.677L62.5392 100.881C62.2898 100.738 62.3221 100.483 62.6085 100.316L65.6848 98.5347C65.9712 98.3681 66.41 98.3496 66.6594 98.493L69.7635 100.289C70.0129 100.432 69.9806 100.687 69.6942 100.853L66.6179 102.635C66.3269 102.806 65.8927 102.825 65.6432 102.677Z"
                  fill="#CDD3DF"/>
              <path
                  d="M70.4381 105.45L67.3341 103.655C67.0847 103.511 67.117 103.257 67.4034 103.09L70.4797 101.308C70.7661 101.142 71.2049 101.123 71.4543 101.266L74.5584 103.062C74.8078 103.206 74.7755 103.46 74.4891 103.627L71.4128 105.409C71.1218 105.575 70.6876 105.594 70.4381 105.45Z"
                  fill="#CDD3DF"/>
              <path
                  d="M75.2321 108.226L72.1281 106.43C71.8786 106.286 71.911 106.032 72.1973 105.865L75.2737 104.084C75.56 103.917 75.9989 103.898 76.2483 104.042L79.3523 105.838C79.6018 105.981 79.5694 106.236 79.283 106.402L76.2067 108.184C75.9157 108.351 75.4815 108.369 75.2321 108.226Z"
                  fill="#CDD3DF"/>
              <path
                  d="M80.027 110.997L76.923 109.201C76.6735 109.058 76.7059 108.803 76.9923 108.637L80.0686 106.855C80.355 106.688 80.7938 106.67 81.0432 106.813L84.1472 108.609C84.3967 108.753 84.3643 109.007 84.078 109.174L81.0016 110.955C80.7106 111.127 80.2764 111.145 80.027 110.997Z"
                  fill="#CDD3DF"/>
              <path
                  d="M84.8219 113.773L81.7179 111.977C81.4685 111.833 81.5008 111.579 81.7872 111.412L84.8635 109.63C85.1499 109.464 85.5887 109.445 85.8381 109.589L88.9422 111.384C89.1916 111.528 89.1593 111.782 88.8729 111.949L85.7966 113.731C85.5056 113.897 85.0714 113.916 84.8219 113.773Z"
                  fill="#CDD3DF"/>
              <path
                  d="M89.6169 116.546L86.5128 114.75C86.2634 114.607 86.2957 114.352 86.5821 114.186L89.6584 112.404C89.9448 112.237 90.3836 112.219 90.6331 112.362L93.7371 114.158C93.9866 114.301 93.9542 114.556 93.6678 114.722L90.5915 116.504C90.3005 116.671 89.8663 116.689 89.6169 116.546Z"
                  fill="#CDD3DF"/>
              <path
                  d="M94.4108 119.317L91.3068 117.522C91.0573 117.378 91.0897 117.124 91.376 116.957L94.4524 115.175C94.7388 115.009 95.1776 114.99 95.427 115.134L98.531 116.929C98.7805 117.073 98.7481 117.327 98.4618 117.494L95.3854 119.276C95.0944 119.442 94.6602 119.461 94.4108 119.317Z"
                  fill="#CDD3DF"/>
              <path
                  d="M99.2057 122.093L96.1017 120.297C95.8523 120.154 95.8846 119.899 96.171 119.733L99.2473 117.951C99.5337 117.784 99.9725 117.766 100.222 117.909L103.326 119.705C103.575 119.848 103.543 120.103 103.257 120.269L100.18 122.051C99.8894 122.218 99.4552 122.236 99.2057 122.093Z"
                  fill="#CDD3DF"/>
              <path
                  d="M107.728 127.022L100.951 123.102C100.702 122.958 100.734 122.704 101.021 122.537L104.097 120.755C104.383 120.589 104.822 120.57 105.072 120.714L111.848 124.634C112.097 124.777 112.065 125.032 111.778 125.198L108.702 126.98C108.416 127.147 107.977 127.165 107.728 127.022Z"
                  fill="#CDD3DF"/>
              <path
                  d="M117.063 121.622L111.345 118.313C111.095 118.169 111.128 117.915 111.414 117.748L114.49 115.966C114.777 115.8 115.216 115.781 115.465 115.925L121.184 119.234C121.433 119.377 121.401 119.632 121.114 119.798L118.038 121.58C117.747 121.747 117.313 121.765 117.063 121.622Z"
                  fill="#CDD3DF"/>
              <path
                  d="M69.2458 99.3624L66.1417 97.5667C65.8923 97.4232 65.9246 97.1686 66.211 97.002L69.2873 95.2202C69.5737 95.0536 70.0125 95.0351 70.262 95.1786L73.366 96.9743C73.6154 97.1177 73.5831 97.3723 73.2967 97.5389L70.2204 99.3207C69.9294 99.4873 69.4952 99.5058 69.2458 99.3624Z"
                  fill="#CDD3DF"/>
              <path
                  d="M64.3307 96.5206L61.2267 94.7249C60.9773 94.5814 61.0096 94.3269 61.296 94.1602L64.3723 92.3784C64.6587 92.2118 65.0975 92.1933 65.3469 92.3368L68.4509 94.1325C68.7004 94.2759 68.668 94.5305 68.3816 94.6971L65.3053 96.4789C65.0143 96.6455 64.5801 96.664 64.3307 96.5206Z"
                  fill="#CDD3DF"/>
              <path
                  d="M59.4167 93.6788L56.3126 91.8831C56.0632 91.7396 56.0955 91.4851 56.3819 91.3184L59.4582 89.5366C59.7446 89.37 60.1834 89.3515 60.4329 89.495L63.5369 91.2907C63.7863 91.4342 63.754 91.6887 63.4676 91.8553L60.3913 93.6371C60.1003 93.8037 59.6661 93.8222 59.4167 93.6788Z"
                  fill="#CDD3DF"/>
              <path
                  d="M54.5016 90.8311L51.3976 89.0354C51.1482 88.8919 51.1805 88.6374 51.4669 88.4708L54.5432 86.689C54.8296 86.5224 55.2684 86.5039 55.5178 86.6473L58.6219 88.443C58.8713 88.5865 58.839 88.841 58.5526 89.0076L55.4763 90.7894C55.1899 90.9607 54.7511 90.9792 54.5016 90.8311Z"
                  fill="#CDD3DF"/>
              <path
                  d="M74.0407 102.134L70.9366 100.338C70.6872 100.195 70.7196 99.9401 71.0059 99.7735L74.0823 97.9917C74.3687 97.8251 74.8075 97.8066 75.0569 97.9501L78.1609 99.7457C78.4104 99.8892 78.378 100.144 78.0917 100.31L75.0153 102.092C74.7243 102.259 74.2901 102.277 74.0407 102.134Z"
                  fill="#CDD3DF"/>
              <path
                  d="M78.8346 104.911L75.7306 103.115C75.4812 102.972 75.5135 102.717 75.7999 102.551L78.8762 100.769C79.1626 100.602 79.6014 100.584 79.8508 100.727L82.9549 102.523C83.2043 102.667 83.172 102.921 82.8856 103.088L79.8093 104.87C79.5183 105.036 79.0841 105.055 78.8346 104.911Z"
                  fill="#CDD3DF"/>
              <path
                  d="M83.6295 107.683L80.5255 105.887C80.2761 105.744 80.3084 105.489 80.5948 105.322L83.6711 103.541C83.9575 103.374 84.3963 103.355 84.6457 103.499L87.7498 105.295C87.9992 105.438 87.9669 105.693 87.6805 105.859L84.6042 107.641C84.3132 107.808 83.879 107.826 83.6295 107.683Z"
                  fill="#CDD3DF"/>
              <path
                  d="M88.4255 110.456L85.3214 108.66C85.072 108.517 85.1043 108.262 85.3907 108.096L88.467 106.314C88.7534 106.147 89.1922 106.129 89.4417 106.272L92.5457 108.068C92.7951 108.212 92.7628 108.466 92.4764 108.633L89.4001 110.414C89.1091 110.581 88.6749 110.6 88.4255 110.456Z"
                  fill="#CDD3DF"/>
              <path
                  d="M93.2184 113.228L90.1144 111.432C89.8649 111.288 89.8973 111.034 90.1837 110.867L93.26 109.085C93.5464 108.919 93.9852 108.9 94.2346 109.044L97.3387 110.839C97.5881 110.983 97.5558 111.238 97.2694 111.404L94.1931 113.186C93.902 113.357 93.4679 113.376 93.2184 113.228Z"
                  fill="#CDD3DF"/>
              <path
                  d="M98.0143 116.005L94.9103 114.209C94.6608 114.066 94.6932 113.811 94.9796 113.645L98.0559 111.863C98.3423 111.696 98.7811 111.678 99.0305 111.821L102.135 113.617C102.384 113.76 102.352 114.015 102.065 114.181L98.989 115.963C98.6979 116.13 98.2638 116.148 98.0143 116.005Z"
                  fill="#CDD3DF"/>
              <path
                  d="M102.809 118.776L99.7052 116.981C99.4558 116.837 99.4881 116.583 99.7745 116.416L102.851 114.634C103.137 114.468 103.576 114.449 103.825 114.593L106.929 116.388C107.179 116.532 107.147 116.786 106.86 116.953L103.784 118.735C103.493 118.901 103.059 118.92 102.809 118.776Z"
                  fill="#CDD3DF"/>
              <path
                  d="M107.603 121.548L104.499 119.752C104.25 119.609 104.282 119.354 104.568 119.188L107.645 117.406C107.931 117.239 108.37 117.221 108.619 117.364L111.723 119.16C111.973 119.303 111.941 119.558 111.654 119.724L108.578 121.506C108.287 121.677 107.853 121.691 107.603 121.548Z"
                  fill="#CDD3DF"/>
              <path
                  d="M112.398 124.325L109.294 122.53C109.045 122.386 109.077 122.132 109.363 121.965L112.44 120.183C112.726 120.017 113.165 119.998 113.414 120.141L116.518 121.937C116.768 122.081 116.735 122.335 116.449 122.502L113.373 124.284C113.082 124.45 112.648 124.469 112.398 124.325Z"
                  fill="#CDD3DF"/>
              <path
                  d="M66.7194 92.501L63.6154 90.7053C63.3659 90.5619 63.3983 90.3073 63.6846 90.1407L66.761 88.3589C67.0474 88.1923 67.4862 88.1738 67.7356 88.3173L70.8396 90.1129C71.0891 90.2564 71.0567 90.5109 70.7703 90.6776L67.694 92.4594C67.403 92.626 66.9688 92.6445 66.7194 92.501Z"
                  fill="#CDD3DF"/>
              <path
                  d="M61.9479 89.7393L58.8439 87.9436C58.5944 87.8001 58.6268 87.5456 58.9132 87.379L61.9895 85.5972C62.2759 85.4306 62.7147 85.4121 62.9641 85.5555L66.0682 87.3512C66.3176 87.4947 66.2853 87.7492 65.9989 87.9158L62.9225 89.6977C62.6362 89.8689 62.1973 89.8828 61.9479 89.7393Z"
                  fill="#CDD3DF"/>
              <path
                  d="M57.1813 86.9815L54.0773 85.1858C53.8278 85.0423 53.8602 84.7878 54.1466 84.6212L57.2229 82.8394C57.5093 82.6728 57.9481 82.6542 58.1975 82.7977L61.3015 84.5934C61.551 84.7369 61.5186 84.9914 61.2323 85.158L58.1559 86.9398C57.8649 87.1064 57.4261 87.125 57.1813 86.9815Z"
                  fill="#CDD3DF"/>
              <path
                  d="M52.4098 84.2237L49.3058 82.428C49.0564 82.2845 49.0887 82.03 49.3751 81.8634L52.4514 80.0816C52.7378 79.9149 53.1766 79.8964 53.426 80.0399L56.5301 81.8356C56.7795 81.9791 56.7472 82.2336 56.4608 82.4002L53.3845 84.182C53.0935 84.3486 52.6593 84.3672 52.4098 84.2237Z"
                  fill="#CDD3DF"/>
              <path
                  d="M57.3151 81.3871L54.2111 79.5914C53.9616 79.4479 53.994 79.1934 54.2803 79.0267L55.2966 78.439C55.5829 78.2724 56.0218 78.2539 56.2712 78.3973L59.3752 80.193C59.6247 80.3365 59.5923 80.591 59.306 80.7576L58.2897 81.3454C57.9987 81.512 57.5645 81.5305 57.3151 81.3871Z"
                  fill="#CDD3DF"/>
              <path
                  d="M63.5426 84.9867L60.4386 83.191C60.1892 83.0475 60.2215 82.793 60.5079 82.6264L61.5241 82.0386C61.8105 81.872 62.2493 81.8535 62.4987 81.9969L65.6028 83.7926C65.8522 83.9361 65.8199 84.1906 65.5335 84.3572L64.5173 84.945C64.2263 85.1116 63.7921 85.1301 63.5426 84.9867Z"
                  fill="#CDD3DF"/>
              <path
                  d="M68.2907 87.7347L65.1866 85.939C64.9372 85.7956 64.9696 85.541 65.2559 85.3744L66.2721 84.7866C66.5585 84.62 66.9973 84.6015 67.2468 84.745L70.3508 86.5407C70.6002 86.6841 70.5679 86.9387 70.2815 87.1053L69.2653 87.6931C68.9743 87.8597 68.5401 87.8782 68.2907 87.7347Z"
                  fill="#CDD3DF"/>
              <path
                  d="M73.0436 90.4808L69.9396 88.6851C69.6901 88.5417 69.7225 88.2871 70.0089 88.1205L71.0251 87.5327C71.3114 87.3661 71.7503 87.3476 71.9997 87.4911L75.1037 89.2868C75.3531 89.4302 75.3208 89.6848 75.0344 89.8514L74.0182 90.4392C73.7272 90.6104 73.293 90.6289 73.0436 90.4808Z"
                  fill="#CDD3DF"/>
              <path
                  d="M77.7917 93.2308L74.6876 91.4351C74.4382 91.2917 74.4705 91.0371 74.7569 90.8705L75.7731 90.2827C76.0595 90.1161 76.4983 90.0976 76.7477 90.2411L79.8518 92.0368C80.1012 92.1802 80.0689 92.4348 79.7825 92.6014L78.7663 93.1892C78.4753 93.3558 78.0411 93.3743 77.7917 93.2308Z"
                  fill="#CDD3DF"/>
              <path
                  d="M104.314 108.571L101.21 106.775C100.961 106.631 100.993 106.377 101.279 106.21L102.296 105.623C102.582 105.456 103.021 105.437 103.27 105.581L106.374 107.377C106.624 107.52 106.591 107.775 106.305 107.941L105.289 108.529C105.002 108.7 104.564 108.719 104.314 108.571Z"
                  fill="#CDD3DF"/>
              <path
                  d="M109.067 111.323L105.963 109.527C105.714 109.383 105.746 109.129 106.032 108.962L107.049 108.375C107.335 108.208 107.774 108.189 108.023 108.333L111.127 110.129C111.377 110.272 111.344 110.527 111.058 110.693L110.042 111.281C109.751 111.448 109.316 111.466 109.067 111.323Z"
                  fill="#CDD3DF"/>
              <path
                  d="M113.816 114.069L110.712 112.273C110.463 112.13 110.495 111.875 110.781 111.708L111.798 111.121C112.084 110.954 112.523 110.935 112.772 111.079L115.876 112.875C116.126 113.018 116.093 113.273 115.807 113.439L114.791 114.027C114.504 114.194 114.066 114.212 113.816 114.069Z"
                  fill="#CDD3DF"/>
              <path
                  d="M118.569 116.817L115.465 115.021C115.216 114.878 115.248 114.623 115.534 114.456L116.55 113.869C116.837 113.702 117.276 113.684 117.525 113.827L120.629 115.623C120.879 115.766 120.846 116.021 120.56 116.187L119.544 116.775C119.253 116.942 118.818 116.96 118.569 116.817Z"
                  fill="#CDD3DF"/>
              <path
                  d="M71.5143 95.2745L68.4103 93.4788C68.1608 93.3353 68.1932 93.0808 68.4796 92.9141L71.5559 91.1323C71.8423 90.9657 72.2811 90.9472 72.5305 91.0907L75.6346 92.8864C75.884 93.0298 75.8516 93.2844 75.5653 93.451L72.4889 95.2328C72.1979 95.3994 71.7637 95.4179 71.5143 95.2745Z"
                  fill="#CDD3DF"/>
              <path
                  d="M76.3083 98.0459L73.2042 96.2503C72.9548 96.1068 72.9871 95.8522 73.2735 95.6856L76.3498 93.9038C76.6362 93.7372 77.075 93.7187 77.3245 93.8622L80.4285 95.6579C80.6779 95.8013 80.6456 96.0559 80.3592 96.2225L77.2829 98.0043C76.9919 98.1709 76.5577 98.1894 76.3083 98.0459Z"
                  fill="#CDD3DF"/>
              <path
                  d="M81.1042 100.823L78.0001 99.0276C77.7507 98.8841 77.783 98.6296 78.0694 98.463L81.1457 96.6812C81.4321 96.5146 81.8709 96.496 82.1204 96.6395L85.2244 98.4352C85.4738 98.5787 85.4415 98.8332 85.1551 98.9998L82.0788 100.782C81.7878 100.948 81.3536 100.967 81.1042 100.823Z"
                  fill="#CDD3DF"/>
              <path
                  d="M85.8991 103.595L82.795 101.799C82.5456 101.656 82.5779 101.401 82.8643 101.234L85.9406 99.4527C86.227 99.286 86.6658 99.2675 86.9153 99.411L90.0193 101.207C90.2687 101.35 90.2364 101.605 89.95 101.771L86.8737 103.553C86.5827 103.72 86.1485 103.738 85.8991 103.595Z"
                  fill="#CDD3DF"/>
              <path
                  d="M90.693 106.366L87.589 104.571C87.3396 104.427 87.3719 104.173 87.6583 104.006L90.7346 102.224C91.021 102.058 91.4598 102.039 91.7092 102.182L94.8133 103.978C95.0627 104.122 95.0304 104.376 94.744 104.543L91.6677 106.325C91.3767 106.491 90.9425 106.51 90.693 106.366Z"
                  fill="#CDD3DF"/>
              <path
                  d="M95.488 109.142L92.3839 107.346C92.1345 107.202 92.1668 106.948 92.4532 106.781L95.5295 105C95.8159 104.833 96.2547 104.814 96.5042 104.958L99.6082 106.754C99.8576 106.897 99.8253 107.152 99.5389 107.318L96.4626 109.1C96.1716 109.271 95.7374 109.29 95.488 109.142Z"
                  fill="#CDD3DF"/>
              <path
                  d="M100.283 111.917L97.1788 110.121C96.9294 109.978 96.9617 109.723 97.2481 109.557L100.324 107.775C100.611 107.608 101.05 107.59 101.299 107.733L104.403 109.529C104.653 109.672 104.62 109.927 104.334 110.094L101.257 111.875C100.966 112.042 100.532 112.061 100.283 111.917Z"
                  fill="#CDD3DF"/>
              <path
                  d="M105.077 114.689L101.973 112.893C101.723 112.749 101.756 112.495 102.042 112.328L105.118 110.546C105.405 110.38 105.844 110.361 106.093 110.505L109.197 112.3C109.447 112.444 109.414 112.698 109.128 112.865L106.051 114.647C105.76 114.813 105.326 114.832 105.077 114.689Z"
                  fill="#CDD3DF"/>
              <path
                  d="M109.872 117.462L106.768 115.666C106.518 115.523 106.551 115.268 106.837 115.102L109.913 113.32C110.2 113.153 110.638 113.135 110.888 113.278L113.992 115.074C114.241 115.217 114.209 115.472 113.923 115.639L110.846 117.42C110.555 117.592 110.121 117.605 109.872 117.462Z"
                  fill="#CDD3DF"/>
              <path
                  d="M44.1049 95.6182L34.9591 90.3284C34.7097 90.1849 34.742 89.9304 35.0284 89.7638L38.1047 87.9819C38.3911 87.8153 38.8299 87.7968 39.0793 87.9403L48.2252 93.2302C48.4746 93.3736 48.4423 93.6282 48.1559 93.7948L45.0796 95.5766C44.7886 95.7432 44.3544 95.7617 44.1049 95.6182Z"
                  fill="#CDD3DF"/>
              <path
                  d="M103.007 129.689L93.8615 124.399C93.612 124.255 93.6444 124.001 93.9307 123.834L97.0071 122.052C97.2934 121.886 97.7323 121.867 97.9817 122.011L107.127 127.3C107.377 127.444 107.345 127.698 107.058 127.865L103.982 129.647C103.691 129.818 103.257 129.837 103.007 129.689Z"
                  fill="#CDD3DF"/>
              <path
                  d="M83.8327 124.722L80.7286 122.926C80.4792 122.783 80.5115 122.528 80.7979 122.361L84.4609 120.242C84.7472 120.075 85.1861 120.057 85.4355 120.2L88.5395 121.996C88.7889 122.139 88.7566 122.394 88.4702 122.56L84.8073 124.68C84.5163 124.851 84.0821 124.87 83.8327 124.722Z"
                  fill="#CDD3DF"/>
              <path
                  d="M49.0145 104.581L44.049 101.707C43.7995 101.564 43.8319 101.309 44.1182 101.143L47.7812 99.023C48.0676 98.8564 48.5064 98.8378 48.7558 98.9813L53.7213 101.855C53.9708 101.999 53.9384 102.253 53.6521 102.42L49.9891 104.54C49.6981 104.711 49.2639 104.729 49.0145 104.581Z"
                  fill="#CDD3DF"/>
              <path
                  d="M79.2147 122.05L74.2491 119.176C73.9997 119.033 74.032 118.778 74.3184 118.611L77.9814 116.492C78.2678 116.325 78.7066 116.307 78.956 116.45L83.9215 119.324C84.171 119.468 84.1386 119.722 83.8523 119.889L80.1893 122.008C79.8983 122.175 79.4641 122.193 79.2147 122.05Z"
                  fill="#CDD3DF"/>
              <path
                  d="M55.9305 108.583L50.965 105.709C50.7155 105.566 50.7479 105.311 51.0343 105.145L54.6972 103.025C54.9836 102.858 55.4224 102.84 55.6718 102.983L60.6374 105.857C60.8868 106.001 60.8545 106.255 60.5681 106.422L56.9051 108.542C56.6187 108.708 56.1799 108.727 55.9305 108.583Z"
                  fill="#CDD3DF"/>
              <path
                  d="M55.7078 103.008L77.2976 115.494C77.5655 115.647 77.5655 115.902 77.2976 116.054L73.5607 118.216C73.2928 118.368 72.8586 118.368 72.5907 118.216L51.001 105.729L55.7078 103.008Z"
                  fill="#CDD3DF"/>
              <path
                  d="M97.2332 129.756L100.693 131.755C100.896 131.876 100.873 132.079 100.637 132.218L99.1362 133.084C98.9007 133.218 98.545 133.236 98.3371 133.116L94.8774 131.117C94.6742 130.996 94.6973 130.793 94.9328 130.654L96.4341 129.788C96.6696 129.654 97.0299 129.64 97.2332 129.756Z"
                  fill="#CDD3DF"/>
              <path
                  d="M92.3972 126.959L95.8569 128.958C96.0602 129.078 96.0371 129.282 95.8015 129.421L94.3003 130.286C94.0647 130.421 93.709 130.439 93.5012 130.319L90.0415 128.319C89.8382 128.199 89.8613 127.995 90.0969 127.857L91.5981 126.991C91.8383 126.852 92.194 126.838 92.3972 126.959Z"
                  fill="#CDD3DF"/>
              <path
                  d="M87.6023 124.187L91.062 126.187C91.2653 126.307 91.2422 126.511 91.0066 126.649L89.5054 127.515C89.2698 127.649 88.9141 127.668 88.7063 127.547L85.2466 125.548C85.0433 125.428 85.0664 125.224 85.302 125.085L86.8032 124.22C87.0434 124.081 87.3991 124.067 87.6023 124.187Z"
                  fill="#CDD3DF"/>
              <path
                  d="M95.0437 125.426L98.5034 127.425C98.7066 127.546 98.6835 127.749 98.448 127.888L96.9468 128.753C96.7112 128.888 96.3555 128.906 96.1477 128.786L92.688 126.787C92.4847 126.666 92.5078 126.463 92.7434 126.324L94.2446 125.458C94.4848 125.324 94.8405 125.306 95.0437 125.426Z"
                  fill="#CDD3DF"/>
              <path
                  d="M65.416 66.1083L74.4985 25.8379L106.619 41.0828L107.031 41.3218L135.752 61.5962L126.726 101.606L65.416 66.1083Z"
                  fill="#81ABEE"/>
              <path
                  d="M106.846 41.1911L97.1656 84.687L61.0957 69.8246L72.4933 25.9375C82.5122 37.8743 106.846 41.1911 106.846 41.1911Z"
                  fill="#C0D6F6"/>
              <path
                  d="M101.601 43.9888C95.1978 42.6586 83.4404 39.5331 74.7871 33.2474L74.8565 32.9648C83.5098 39.2506 95.2672 42.3804 101.67 43.7063L101.601 43.9888Z"
                  fill="#3D75E4"/>
              <path
                  d="M101.128 45.7974C94.7251 44.4672 82.9677 41.3417 74.3145 35.056L74.3838 34.7734C83.0371 41.0592 94.7945 44.189 101.198 45.5148L101.128 45.7974Z"
                  fill="#3D75E4"/>
              <path
                  d="M100.657 47.604C94.2535 46.2738 82.4961 43.1483 73.8428 36.8626L73.9121 36.5801C82.5654 42.8658 94.3228 45.9956 100.726 47.3214L100.657 47.604Z"
                  fill="#3D75E4"/>
              <path
                  d="M100.184 49.4126C93.7808 48.0824 82.0234 44.957 73.3701 38.6712L73.4395 38.3887C82.0928 44.6744 93.8501 47.8042 100.253 49.1301L100.184 49.4126Z"
                  fill="#3D75E4"/>
              <path
                  d="M99.7118 51.2217C93.3042 49.8915 81.5512 46.766 72.8936 40.4759L72.9629 40.1934C81.6162 46.4791 93.3736 49.6089 99.7768 50.9348L99.7118 51.2217Z"
                  fill="#3D75E4"/>
              <path
                  d="M99.2338 53.0302C92.8306 51.7001 81.0775 48.5702 72.4199 42.2845L72.4893 42.002C81.1426 48.2877 92.8999 51.4175 99.3032 52.7433L99.2338 53.0302Z"
                  fill="#3D75E4"/>
              <path
                  d="M98.7612 54.8364C92.358 53.5062 80.6006 50.3808 71.9473 44.0951L72.0166 43.8125C80.6699 50.0982 92.4273 53.228 98.8306 54.5539L98.7612 54.8364Z"
                  fill="#3D75E4"/>
              <path
                  d="M98.2885 56.6489C91.8853 55.3188 80.1279 52.1933 71.4746 45.9076L71.544 45.625C80.1973 51.9107 91.9546 55.0406 98.3579 56.3664L98.2885 56.6489Z"
                  fill="#3D75E4"/>
              <path
                  d="M97.8159 58.4497C91.4126 57.1196 79.6553 53.9941 71.002 47.7083L71.0713 47.4258C79.7246 53.7115 91.482 56.8413 97.8853 58.1672L97.8159 58.4497Z"
                  fill="#3D75E4"/>
              <path
                  d="M97.3442 60.2583C90.9409 58.9281 79.1836 55.8027 70.5303 49.5169L70.5996 49.2344C79.2529 55.5201 91.0103 58.6499 97.4136 59.9758L97.3442 60.2583Z"
                  fill="#3D75E4"/>
              <path
                  d="M96.8715 62.0689C90.4683 60.7387 78.7109 57.6132 70.0576 51.3275L70.127 51.0449C78.7803 57.3307 90.5376 60.4605 96.9409 61.7863L96.8715 62.0689Z"
                  fill="#3D75E4"/>
              <path
                  d="M96.3989 63.8735C89.9957 62.5434 78.2383 59.4179 69.585 53.1322L69.6543 52.8496C78.3076 59.1353 90.065 62.2652 96.4683 63.591L96.3989 63.8735Z"
                  fill="#3D75E4"/>
              <path
                  d="M95.9262 65.6821C89.523 64.3519 77.7656 61.2265 69.1123 54.9408L69.1817 54.6582C77.835 60.9439 89.5923 64.0737 95.9956 65.3995L95.9262 65.6821Z"
                  fill="#3D75E4"/>
              <path
                  d="M95.4497 67.4907C89.0464 66.1605 77.289 63.0351 68.6357 56.7493L68.7051 56.4668C77.3584 62.7525 89.1158 65.8823 95.519 67.2081L95.4497 67.4907Z"
                  fill="#3D75E4"/>
              <path
                  d="M94.976 69.2993C88.5727 67.9692 76.8154 64.8437 68.1621 58.5579L68.2315 58.2754C76.8847 64.5611 88.6421 67.691 95.0453 69.0168L94.976 69.2993Z"
                  fill="#3D75E4"/>
              <path
                  d="M94.5053 71.1079C88.1021 69.7778 76.3447 66.6523 67.6914 60.3665L67.7608 60.084C76.4141 66.3697 88.1714 69.4996 94.5747 70.8254L94.5053 71.1079Z"
                  fill="#3D75E4"/>
              <path
                  d="M94.0307 72.9185C87.6274 71.5883 75.8701 68.4628 67.2168 62.1771L67.2862 61.8945C75.9394 68.1803 87.6968 71.3101 94.1001 72.6359L94.0307 72.9185Z"
                  fill="#3D75E4"/>
              <path
                  d="M93.559 74.7251C87.1557 73.3949 75.3984 70.2695 66.7451 63.9837L66.8145 63.7012C75.4677 69.9869 87.2251 73.1167 93.6283 74.4426L93.559 74.7251Z"
                  fill="#3D75E4"/>
              <path
                  d="M93.0864 76.5337C86.6831 75.2035 74.9257 72.078 66.2725 65.7923L66.3418 65.5098C74.9951 71.7955 86.7525 74.9253 93.1557 76.2511L93.0864 76.5337Z"
                  fill="#3D75E4"/>
              <path
                  d="M106.846 41.1934L97.1693 84.6893C97.0479 99.1865 126.077 104.838 126.077 104.838L135.754 61.3416C135.754 61.3416 108.953 51.5392 106.846 41.1934Z"
                  fill="#E4EDFB"/>
              <path
                  d="M100.7 84.7363C101.021 92.7782 115.319 98.1946 123.508 100.351L123.46 100.664C115.275 98.5076 100.96 93.0869 100.644 85.045L100.7 84.7363Z"
                  fill="#3D75E4"/>
              <path
                  d="M101.217 82.4141C101.724 90.3473 116.03 95.7289 124.055 97.8937L124.007 98.2067C115.983 96.0419 101.646 90.6516 101.16 82.727L101.217 82.4141Z"
                  fill="#3D75E4"/>
              <path
                  d="M101.731 80.0938C102.425 87.9183 116.744 93.2651 124.6 95.4386L124.552 95.7516C116.688 93.5737 102.329 88.2139 101.675 80.4067L101.731 80.0938Z"
                  fill="#3D75E4"/>
              <path
                  d="M102.252 77.7715C103.132 85.4917 117.46 90.7994 125.151 92.9816L125.103 93.2946C117.399 91.108 103.015 85.7786 102.195 78.0845L102.252 77.7715Z"
                  fill="#3D75E4"/>
              <path
                  d="M102.768 75.4512C103.835 83.0628 118.172 88.3357 125.698 90.5266L125.65 90.8395C118.111 88.64 103.7 83.341 102.712 75.7685L102.768 75.4512Z"
                  fill="#3D75E4"/>
              <path
                  d="M103.283 73.1289C104.536 80.6318 118.881 85.8656 126.243 88.0695L126.195 88.3781C118.816 86.1699 104.38 80.9013 103.227 73.4419L103.283 73.1289Z"
                  fill="#3D75E4"/>
              <path
                  d="M103.8 70.8125C105.239 78.2111 119.594 83.4057 126.795 85.6183L126.747 85.9269C119.529 83.7056 105.07 78.4675 103.748 71.1298L103.8 70.8125Z"
                  fill="#3D75E4"/>
              <path
                  d="M104.32 68.4922C105.945 75.7821 120.308 80.9419 127.344 83.1589L127.297 83.4675C120.239 81.2375 105.755 76.0255 104.268 68.8052L104.32 68.4922Z"
                  fill="#3D75E4"/>
              <path
                  d="M104.836 66.1719C106.648 73.3531 121.024 78.4782 127.891 80.7038L127.844 81.0125C120.951 78.7694 106.44 73.5922 104.784 66.4849L104.836 66.1719Z"
                  fill="#3D75E4"/>
              <path
                  d="M105.352 63.8457C107.355 70.9226 121.735 76.0086 128.437 78.2429L128.39 78.5515C121.657 76.2998 107.121 71.1486 105.3 64.163L105.352 63.8457Z"
                  fill="#3D75E4"/>
              <path
                  d="M105.868 61.5254C108.058 68.4936 122.447 73.5448 128.984 75.7878L128.937 76.0921C122.364 73.8273 107.806 68.7109 105.816 61.8384L105.868 61.5254Z"
                  fill="#3D75E4"/>
              <path
                  d="M106.388 59.2051C108.764 66.0646 123.161 71.081 129.534 73.3328L129.486 73.6371C123.075 71.3636 108.491 66.2733 106.336 59.5181L106.388 59.2051Z"
                  fill="#3D75E4"/>
              <path
                  d="M106.903 56.8848C109.465 63.64 123.871 68.6129 130.079 70.8777L130.032 71.182C123.78 68.8998 109.174 63.8356 106.851 57.2021L106.903 56.8848Z"
                  fill="#3D75E4"/>
              <path
                  d="M107.419 54.5625C110.168 61.209 124.587 66.1472 130.626 68.4207L130.578 68.725C124.492 66.4298 109.856 61.4003 107.367 54.8798L107.419 54.5625Z"
                  fill="#3D75E4"/>
              <path
                  d="M107.935 52.2402C110.87 58.7825 125.298 63.6815 131.172 65.9637L131.124 66.268C125.198 63.9641 110.54 58.9607 107.883 52.5619L107.935 52.2402Z"
                  fill="#3D75E4"/>
              <path
                  d="M108.403 50.2373L108.455 49.9199C111.577 56.3534 126.013 61.2177 131.723 63.5086L131.675 63.8085C125.909 61.4916 111.226 56.523 108.403 50.2373Z"
                  fill="#3D75E4"/>
              <path opacity="0.32"
                    d="M100.686 84.4024L104.592 51.3349L106.846 41.1934C107.345 43.6494 109.248 46.0707 111.84 48.3441C117.463 55.2472 124.738 59.3072 124.738 59.3072L115.304 101.712C110.904 100.078 106.027 97.765 102.437 94.6656C100.187 91.9009 99.0207 88.4624 100.686 84.4024Z"
                    fill="#81ABEE"/>
              <path
                  d="M107.427 47.1096C106.742 46.175 106.538 43.3234 106.846 41.1934L97.1697 84.6893C94.4167 96.6087 111.546 103.09 111.546 103.09L121.222 59.5941C121.222 59.5941 112.525 55.3949 107.427 47.1096Z"
                  fill="#E4EDFB"/>
              <path
                  d="M117.011 62.4236C114.613 60.95 106.198 55.1207 105.526 48.435L105.635 47.9395C106.242 54.6599 114.726 60.5327 117.132 62.015L117.011 62.4236Z"
                  fill="#3D75E4"/>
              <path
                  d="M116.356 65.3893C113.958 63.9157 105.452 58.0733 104.937 51.1225L105.045 50.627C105.504 57.6126 114.071 63.5027 116.477 64.9807L116.356 65.3893Z"
                  fill="#3D75E4"/>
              <path
                  d="M115.7 68.3535C113.303 66.8799 104.702 61.0245 104.351 53.8042L104.459 53.3086C104.767 60.5594 113.42 66.4626 115.826 67.9449L115.7 68.3535Z"
                  fill="#3D75E4"/>
              <path
                  d="M115.043 71.3231C112.645 69.8495 103.953 63.9811 103.758 56.4956L103.866 56C104.022 63.5159 112.758 69.4365 115.164 70.9145L115.043 71.3231Z"
                  fill="#3D75E4"/>
              <path
                  d="M114.387 74.2873C111.989 72.8136 103.202 66.9322 103.167 59.1772L103.275 58.6816C103.284 66.4627 112.102 72.3963 114.508 73.8787L114.387 74.2873Z"
                  fill="#3D75E4"/>
              <path
                  d="M113.734 77.2515C111.336 75.7779 102.458 69.8834 102.583 61.8588L102.692 61.3633C102.549 69.4095 111.453 75.3606 113.859 76.8385L113.734 77.2515Z"
                  fill="#3D75E4"/>
              <path
                  d="M113.078 80.2133C110.681 78.7397 101.707 72.8321 101.993 64.5424L102.101 64.0469C101.811 72.3583 110.794 78.3267 113.2 79.8047L113.078 80.2133Z"
                  fill="#3D75E4"/>
              <path
                  d="M112.42 83.1854C110.022 81.7117 100.957 75.7911 101.399 67.2319L101.508 66.7363C101.066 75.3129 110.135 81.2944 112.541 82.7767L112.42 83.1854Z"
                  fill="#3D75E4"/>
              <path
                  d="M111.764 86.1471C109.367 84.6735 100.206 78.7398 100.813 69.9155L100.921 69.4199C100.327 78.2617 109.479 84.2605 111.885 85.7385L111.764 86.1471Z"
                  fill="#3D75E4"/>
              <path
                  d="M111.11 89.1133C108.713 87.6396 99.4613 81.693 100.224 72.5991L100.333 72.1035C99.5913 81.2104 108.826 87.2223 111.232 88.7046L111.11 89.1133Z"
                  fill="#3D75E4"/>
              <path
                  d="M110.451 92.077C108.054 90.6034 98.7069 84.6437 99.6303 75.2846L99.7387 74.7891C98.8456 84.1612 108.167 90.1904 110.573 91.6684L110.451 92.077Z"
                  fill="#3D75E4"/>
              <path
                  d="M109.797 95.049C107.399 93.5754 97.9615 87.6026 99.0453 77.9741L99.1537 77.4785C98.1132 87.1158 107.512 93.1581 109.918 94.6404L109.797 95.049Z"
                  fill="#3D75E4"/>
              <path
                  d="M109.142 98.0132C106.744 96.5396 97.211 90.5538 98.4552 80.6557L98.5636 80.1602C97.3714 90.0626 106.857 96.1223 109.263 97.6003L109.142 98.0132Z"
                  fill="#3D75E4"/>
              <path
                  d="M59.8984 78.99L69.5792 35.4941C69.5792 35.4941 89.1011 42.6536 106.846 41.1887L97.1648 84.6846C79.4204 86.1495 59.8984 78.99 59.8984 78.99Z"
                  fill="#E4EDFB"/>
              <path
                  d="M71.873 41.1264L71.9381 40.7656C77.7734 42.5522 89.6001 45.6386 101.691 45.6386C101.891 45.6386 102.09 45.6386 102.29 45.6386L102.259 45.8559C90.0857 46.1515 77.7257 42.9174 71.873 41.1264Z"
                  fill="#3D75E4"/>
              <path
                  d="M71.3789 43.3471L71.4439 42.9863C77.2793 44.7729 89.106 47.8593 101.197 47.8593C101.397 47.8593 101.596 47.8593 101.795 47.8593L101.765 48.0767C89.5915 48.3723 77.2272 45.1381 71.3789 43.3471Z"
                  fill="#3D75E4"/>
              <path
                  d="M70.8838 45.5698L70.9488 45.209C76.7842 46.9956 88.6109 50.0819 100.702 50.0819C100.902 50.0819 101.101 50.0819 101.3 50.0819L101.27 50.2993C89.0965 50.5949 76.7321 47.3607 70.8838 45.5698Z"
                  fill="#3D75E4"/>
              <path
                  d="M70.3896 47.7885L70.4547 47.4277C76.29 49.2143 88.1167 52.3007 100.208 52.3007C100.407 52.3007 100.607 52.3007 100.806 52.3007L100.776 52.518C88.6023 52.8136 76.238 49.5795 70.3896 47.7885Z"
                  fill="#3D75E4"/>
              <path
                  d="M69.8955 50.0112L69.9605 49.6504C75.7959 51.437 87.6226 54.5233 99.7137 54.5233C99.9131 54.5233 100.113 54.5233 100.312 54.5233L100.282 54.7407C88.1081 55.0363 75.7438 51.8021 69.8955 50.0112Z"
                  fill="#3D75E4"/>
              <path
                  d="M69.4023 52.2338L69.4674 51.873C75.3027 53.6597 87.1294 56.746 99.2206 56.746C99.4201 56.746 99.6195 56.746 99.8189 56.746L99.7886 56.9634C87.615 57.2589 75.2507 54.0248 69.4023 52.2338Z"
                  fill="#3D75E4"/>
              <path
                  d="M68.9082 54.4545L68.9732 54.0938C74.8086 55.8803 86.6353 58.9667 98.7265 58.9667C98.9259 58.9667 99.1253 58.9667 99.3247 58.9667L99.2944 59.184C87.1208 59.4796 74.7565 56.2455 68.9082 54.4545Z"
                  fill="#3D75E4"/>
              <path
                  d="M68.4131 56.6753L68.4781 56.3145C74.3134 58.1011 86.1401 61.1874 98.2313 61.1874C98.4307 61.1874 98.6302 61.1874 98.8296 61.1874L98.7992 61.4048C86.6257 61.7004 74.2614 58.4662 68.4131 56.6753Z"
                  fill="#3D75E4"/>
              <path
                  d="M67.9189 58.8999L67.984 58.5391C73.8193 60.3257 85.6461 63.412 97.7373 63.412C97.9367 63.412 98.1361 63.412 98.3355 63.412L98.3052 63.6294C86.1316 63.925 73.7673 60.6908 67.9189 58.8999Z"
                  fill="#3D75E4"/>
              <path
                  d="M67.4238 61.1245L67.4889 60.7637C73.3242 62.5503 85.1509 65.6366 97.2421 65.6366C97.4415 65.6366 97.6409 65.6366 97.8403 65.6366L97.81 65.854C85.6321 66.1452 73.2722 62.9111 67.4238 61.1245Z"
                  fill="#3D75E4"/>
              <path
                  d="M66.9307 63.3452L66.9957 62.9844C72.831 64.771 84.6578 67.8573 96.7489 67.8573C96.9484 67.8573 97.1478 67.8573 97.3472 67.8573L97.3169 68.0747C85.139 68.3659 72.779 65.1318 66.9307 63.3452Z"
                  fill="#3D75E4"/>
              <path
                  d="M66.4365 65.5659L66.5016 65.2051C72.3369 66.9917 84.1636 70.0781 96.2548 70.0781C96.4542 70.0781 96.6536 70.0781 96.8531 70.0781L96.8227 70.2954C84.6448 70.5866 72.2849 67.3568 66.4365 65.5659Z"
                  fill="#3D75E4"/>
              <path
                  d="M65.9375 67.7885L66.0025 67.4277C71.8379 69.2143 83.6646 72.3007 95.7558 72.3007C95.9552 72.3007 96.1546 72.3007 96.354 72.3007L96.3237 72.518C84.1501 72.8136 71.7902 69.5795 65.9375 67.7885Z"
                  fill="#3D75E4"/>
              <path
                  d="M65.4434 70.0073L65.5084 69.6465C71.3437 71.4331 83.1704 74.5194 95.2616 74.5194C95.461 74.5194 95.6605 74.5194 95.8599 74.5194L95.8295 74.7368C83.656 75.0324 71.296 71.7982 65.4434 70.0073Z"
                  fill="#3D75E4"/>
              <path
                  d="M64.9502 72.228L65.0152 71.8672C70.8506 73.6538 82.6773 76.7401 94.7685 76.7401C94.9679 76.7401 95.1673 76.7401 95.3668 76.7401L95.3364 76.9575C83.1628 77.2531 70.8029 74.0189 64.9502 72.228Z"
                  fill="#3D75E4"/>
              <path
                  d="M64.4561 74.4487L64.5211 74.0879C70.3564 75.8745 82.1831 78.9608 94.2743 78.9608C94.4737 78.9608 94.6731 78.9608 94.8726 78.9608L94.8422 79.1782C82.6687 79.4738 70.3087 76.2396 64.4561 74.4487Z"
                  fill="#3D75E4"/>
              <path
                  d="M63.9609 76.6713L64.026 76.3105C69.8613 78.0972 81.688 81.1835 93.7792 81.1835C93.9786 81.1835 94.1781 81.1835 94.3775 81.1835L94.3472 81.4009C82.1736 81.6964 69.8136 78.4623 63.9609 76.6713Z"
                  fill="#3D75E4"/>
              <path
                  d="M73.8333 123.448L31.2214 148.116L0 130.039C10.1687 124.152 36.7912 108.743 42.6119 105.371C47.2804 108.073 55.5353 112.856 73.8333 123.448Z"
                  fill="#FBECDD"/>
              <path d="M54.5826 135.244L40.9764 148.892L34.6494 146.782L54.5826 135.244Z" fill="#F8D6B5"/>
              <path d="M40.9756 148.89L54.5818 135.242L56.1101 134.357L40.9756 149.537V148.89Z" fill="#E9A35D"/>
              <path d="M40.9764 148.889V149.537L33.9434 147.189L34.6495 146.779L40.9764 148.889Z" fill="#E9A35D"/>
              <path d="M38.4681 107.772L20.7832 118.01L32.8611 105.895L38.4681 107.772Z" fill="#F8D6B5"/>
              <path
                  d="M31.2217 148.117L73.8336 123.449V124.101C69.1046 126.835 31.361 148.686 31.2217 148.769V148.117V148.117Z"
                  fill="#E9A35D"/>
              <path d="M18.4279 141.354L2.72656 136.109L5.15144 133.678L18.4279 141.354Z" fill="#F8D6B5"/>
              <path d="M2.72656 136.109L18.4279 141.354L21.0711 142.891L2.72656 136.757V136.109Z" fill="#E9A35D"/>
              <path d="M0 130.041L31.2214 148.118V148.77C28.2624 147.056 1.50974 131.564 0 130.688V130.041Z"
                    fill="#E9A35D"/>
              <path
                  d="M79.8823 119.7L72.9468 123.715L39.4678 104.333L46.4033 100.318C46.4544 100.351 76.8303 117.93 79.8823 119.7Z"
                  fill="#F8D6B5"/>
              <path d="M79.8808 120.639L72.9453 124.654V123.718L79.8808 119.703V120.639Z" fill="#E9A35D"/>
              <path d="M72.9459 124.654L39.4668 105.272V104.336L72.9459 123.717V124.654Z" fill="#E9A35D"/>
              <path
                  d="M49.1473 101.915C44.2092 104.775 44.5251 104.612 44.4183 104.612C44.1489 104.612 44.0699 104.263 44.2929 104.132L48.6363 101.617L49.1473 101.915Z"
                  fill="#D06E0B"/>
              <path
                  d="M52.2873 103.729C47.3911 106.566 47.6606 106.431 47.5584 106.431C47.2982 106.431 47.2007 106.081 47.4329 105.951L51.7717 103.436L52.2873 103.729Z"
                  fill="#D06E0B"/>
              <path
                  d="M55.4236 105.546C50.4856 108.401 50.7969 108.243 50.6947 108.243C50.4438 108.243 50.3323 107.898 50.5646 107.763L54.908 105.248L55.4236 105.546Z"
                  fill="#D06E0B"/>
              <path
                  d="M58.5591 107.357C53.6118 110.217 53.9324 110.054 53.8302 110.054C53.5654 110.054 53.4771 109.704 53.7001 109.574L58.0435 107.059L58.5591 107.357Z"
                  fill="#D06E0B"/>
              <path
                  d="M61.6944 109.173C56.7564 112.028 57.0677 111.87 56.9655 111.87C56.6914 111.87 56.6217 111.516 56.84 111.395L61.1835 108.875L61.6944 109.173Z"
                  fill="#D06E0B"/>
              <path
                  d="M64.8348 110.991C59.8875 113.855 60.2033 113.692 60.1058 113.692C59.8549 113.692 59.7435 113.352 59.9757 113.213C64.3749 110.665 64.2913 110.697 64.3238 110.697L64.8348 110.991Z"
                  fill="#D06E0B"/>
              <path
                  d="M67.9664 112.803C63.0191 115.668 63.3396 115.505 63.2374 115.505C62.9726 115.505 62.8843 115.155 63.1073 115.025L67.4507 112.51L67.9664 112.803Z"
                  fill="#D06E0B"/>
              <path
                  d="M71.107 114.62C66.1597 117.48 66.4802 117.317 66.378 117.317C66.1132 117.317 66.025 116.968 66.248 116.838L70.5913 114.322L71.107 114.62Z"
                  fill="#D06E0B"/>
              <path
                  d="M74.2417 116.435C69.3455 119.271 69.6149 119.136 69.5127 119.136C69.2479 119.136 69.1597 118.782 69.3873 118.657L73.7307 116.137L74.2417 116.435Z"
                  fill="#D06E0B"/>
              <path
                  d="M77.3777 118.247C72.4304 121.111 72.7463 120.948 72.6488 120.948C72.3979 120.948 72.2864 120.608 72.5187 120.468L76.8667 117.953L77.3777 118.247Z"
                  fill="#D06E0B"/>
              <path
                  d="M38.239 111.371C37.3238 112.508 37.3285 112.559 37.3053 112.582C37.2774 112.61 37.3053 112.694 37.5375 113.02C37.1194 113.752 36.7757 114.776 36.9243 115.563H36.9104C36.5295 115.345 36.3994 114.874 36.3994 114.432C36.3994 113.244 37.282 111.833 38.2297 111.357L38.239 111.371Z"
                  fill="#81ABEE"/>
              <path
                  d="M73.766 133.068C74.1887 133.571 74.6068 134.074 75.0295 134.577C75.1364 134.707 75.0249 134.898 74.8623 134.87C74.2073 134.754 73.5523 134.637 72.8927 134.521C72.7394 134.488 72.6419 134.349 72.6233 134.153C72.5815 133.701 72.9624 133.1 73.3572 132.965C73.543 132.905 73.687 132.956 73.766 133.068Z"
                  fill="#3D75E4"/>
              <path
                  d="M44.9515 114.51C74.3519 131.535 70.5149 129.239 70.2129 129.239C70.0457 129.225 69.8599 129.253 69.6555 129.332C69.2002 129.499 68.7403 129.914 68.4802 130.263C68.4755 130.263 68.4756 130.268 68.4756 130.273C68.0296 130.822 67.7369 131.535 67.6626 132.052C67.6022 132.392 67.6208 132.746 67.6533 132.881C67.6766 133.063 67.7973 133.31 67.8159 133.319C67.867 133.421 68.0064 133.561 68.0853 133.608C40.9704 117.911 41.565 118.269 41.5743 118.251C41.4396 118.153 41.3421 118.022 41.2677 117.864C41.0587 117.408 41.1237 116.807 41.2631 116.327C41.3746 115.95 41.5557 115.558 41.7927 115.204C44.0178 116.49 46.2429 117.78 48.468 119.066L49.4389 117.855L49.3692 117.752C47.2509 116.527 45.1327 115.298 43.0097 114.073C43.3674 113.896 43.7994 113.821 44.1339 114.035C44.4033 114.194 44.6774 114.352 44.9515 114.51Z"
                  fill="#81ABEE"/>
              <path
                  d="M70.8866 129.633C70.8819 129.633 70.8866 129.633 70.8866 129.633C70.9377 129.717 70.9284 129.703 70.8866 129.633ZM68.5778 133.741C68.55 133.741 68.5221 133.741 68.4942 133.737C68.5221 133.741 68.55 133.741 68.5778 133.741ZM72.8934 134.519C71.4115 134.258 69.9343 133.998 68.4524 133.737C68.4756 133.737 68.4849 133.741 68.4478 133.737C68.3781 133.727 68.3316 133.718 68.248 133.686C68.2387 133.681 68.2248 133.676 68.2155 133.672C68.0901 133.616 67.9786 133.532 67.895 133.429C67.8578 133.387 67.8532 133.369 67.816 133.318C67.8113 133.318 67.8113 133.313 67.8113 133.313C67.7649 133.234 67.7231 133.145 67.6906 133.043C67.6859 133.033 67.6441 132.866 67.6441 132.838C67.5698 132.288 67.6906 131.752 67.8996 131.245C67.8996 131.245 67.8996 131.24 67.9043 131.235C67.9972 131.012 68.1365 130.746 68.3363 130.458C68.3595 130.416 68.4756 130.271 68.4756 130.267C68.7822 129.885 69.2468 129.47 69.702 129.316C69.925 129.237 70.1712 129.223 70.2687 129.247C70.278 129.247 70.3384 129.26 70.3431 129.26C70.4035 129.27 70.4871 129.302 70.515 129.321C70.6775 129.4 70.7101 129.442 70.8169 129.549C70.8401 129.577 70.5939 129.284 73.7342 133.033C73.6459 132.945 73.5112 132.922 73.3626 132.973C72.9677 133.108 72.5868 133.709 72.6286 134.161C72.6425 134.352 72.7401 134.491 72.8934 134.519Z"
                  fill="#C0D6F6"/>
              <path d="M48.3936 118.963L49.3644 117.752L49.4341 117.854L48.4679 119.065L48.3936 118.963Z"
                    fill="#3D75E4"/>
              <path
                  d="M40.5015 111.323C44.5523 113.671 44.3757 113.55 44.3664 113.568C43.8276 113.247 43.1587 113.499 42.643 113.857C41.3563 113.112 40.0648 112.362 38.7734 111.617C38.8989 111.528 39.0289 111.444 39.1683 111.375C39.5539 111.184 40.1206 111.104 40.5015 111.323Z"
                  fill="#C0D6F6"/>
              <path
                  d="M42.6426 113.863C43.1582 113.5 43.8271 113.253 44.366 113.575C44.7144 113.775 44.8816 114.11 44.9513 114.511C44.9327 114.502 44.0873 114.012 44.0687 113.998C43.7528 113.835 43.3626 113.896 43.0049 114.073C42.8841 114.003 42.7634 113.933 42.6426 113.863Z"
                  fill="#C0D6F6"/>
              <path
                  d="M40.7662 117.464C40.7384 116.644 41.0821 115.745 41.5327 115.051C41.621 115.102 41.7092 115.153 41.7975 115.204C41.5559 115.563 41.3748 115.95 41.2679 116.327C41.1286 116.807 41.0635 117.408 41.2726 117.864C41.3423 118.023 41.4445 118.153 41.5792 118.251V118.255C41.8858 118.432 42.1924 118.609 42.4943 118.786C42.0948 118.931 41.7092 118.949 41.3423 118.721C40.9102 118.46 40.7802 117.962 40.7662 117.464Z"
                  fill="#C0D6F6"/>
              <path
                  d="M38.249 111.347C38.6114 111.166 39.0294 111.077 39.3778 111.282L39.3825 111.291C39.1827 111.356 38.9458 111.501 38.7786 111.622C38.4581 111.436 38.3141 111.343 38.2583 111.361L38.249 111.347Z"
                  fill="#81ABEE"/>
              <path
                  d="M37.5151 116.513C37.2225 116.345 37.0413 116.01 36.953 115.679C36.7347 114.766 37.1621 113.578 37.6638 112.814C38.9552 113.56 40.2419 114.305 41.5333 115.055C41.5333 115.074 40.725 116.196 40.7668 117.468C40.7808 117.966 40.9155 118.464 41.3382 118.725C41.3289 118.739 41.4451 118.781 37.5151 116.513Z"
                  fill="#C0D6F6"/>
              <path
                  d="M37.663 112.809C37.6352 112.85 37.6073 112.897 37.5748 112.948C37.5005 112.841 37.4261 112.739 37.3564 112.637C37.4586 112.692 37.5608 112.753 37.663 112.809Z"
                  fill="#3D75E4"/>
              <path
                  d="M37.3011 112.679C37.2036 112.534 37.3104 112.544 37.4637 112.637C41.1057 114.747 44.7523 116.857 48.3942 118.967L48.4686 119.069C43.1403 115.986 38.0908 113.061 37.3568 112.642C37.4265 112.749 37.5009 112.851 37.5752 112.954C37.5659 112.972 37.552 112.996 37.538 113.019C37.459 112.902 37.3801 112.791 37.3011 112.679Z"
                  fill="#3D75E4"/>
              <path
                  d="M37.2725 112.577C37.5976 112.176 37.9182 111.771 38.2433 111.37L38.2387 111.361C38.2433 111.356 38.248 111.356 38.2526 111.352L38.2573 111.361C38.327 111.338 37.8578 111.091 49.369 117.751L48.3981 118.963C36.9473 112.334 37.3189 112.525 37.2725 112.577Z"
                  fill="#3D75E4"/>
              <path d="M47.8555 35.885V53.1566L50.4539 51.7229V34.3125L47.8555 35.885Z" fill="#E14761"/>
              <path d="M47.8702 35.8825V53.1541L12 32.0901V14.6797L47.8702 35.8825Z" fill="#F196A5"/>
              <path d="M12 14.6773L47.8702 35.8802L50.4532 34.3077L14.5471 13.1562L12 14.6773Z" fill="#F7CFD5"/>
              <path d="M30.4395 29.9941V31.6437L44.8767 40.0097V38.3602L30.4395 29.9941Z" fill="white"/>
              <path d="M36.8458 40.5694L30.4395 36.8438V38.4882L36.8458 42.2139V40.5694Z" fill="white"/>
              <path d="M30.4395 35.0675L44.8767 43.4336V41.7891L30.4395 33.418V35.0675Z" fill="white"/>
              <path
                  d="M26.6478 26.2396L16.8436 20.5611C16.244 20.2117 15.752 20.4892 15.752 21.1829V27.8429C15.752 28.5366 16.2388 29.3794 16.8436 29.7288L17.4586 30.0834V33.6086L20.5234 31.8563L26.6529 35.4021C27.2525 35.7515 27.7445 35.474 27.7445 34.7803V28.1204C27.7343 27.4318 27.2474 26.589 26.6478 26.2396ZM19.6009 27.4061C19.2831 27.5859 18.7655 27.2879 18.4477 26.738C18.13 26.1882 18.13 25.5921 18.4477 25.4071C18.7655 25.2221 19.2831 25.5253 19.6009 26.0751C19.9186 26.6301 19.9186 27.2211 19.6009 27.4061ZM22.2556 28.9426C21.9379 29.1224 21.4202 28.8244 21.1025 28.2745C20.7847 27.7247 20.7847 27.1286 21.1025 26.9436C21.4202 26.7637 21.9379 27.0618 22.2556 27.6116C22.5734 28.1666 22.5734 28.7627 22.2556 28.9426ZM24.9104 30.4791C24.5926 30.6589 24.075 30.3609 23.7573 29.811C23.4395 29.2612 23.4395 28.6651 23.7573 28.4801C24.075 28.3002 24.5926 28.5983 24.9104 29.1481C25.2281 29.7031 25.2281 30.2992 24.9104 30.4791Z"
                  fill="white"/>
              <path
                  d="M115.585 25.8467C114.714 25.3367 113.923 25.2888 113.353 25.6236L112.236 26.2771C112.806 25.9424 113.598 25.9902 114.469 26.5003C116.203 27.5125 117.604 29.9514 117.596 31.9599C117.596 32.9562 117.24 33.6576 116.678 33.9844L117.794 33.3308C118.356 33.004 118.704 32.3027 118.712 31.3064C118.728 29.2979 117.319 26.851 115.585 25.8467Z"
                  fill="#3D75E4"/>
              <path
                  d="M114.469 26.498C116.203 27.5102 117.604 29.9491 117.596 31.9576C117.588 33.9661 116.179 34.7711 114.445 33.7669C112.712 32.7626 111.31 30.3158 111.318 28.3073C111.326 26.2988 112.735 25.4858 114.469 26.498Z"
                  fill="#81ABEE"/>
              <path
                  d="M115.608 18.4585C113.194 17.0557 110.779 16.713 108.935 17.773L107.818 18.4266C109.663 17.3666 112.078 17.7093 114.492 19.112C116.004 19.9888 117.508 21.2799 118.87 22.9059C119.685 23.8782 120.461 24.9781 121.142 26.1737L122.258 25.5201C120.43 22.3241 118.023 19.8612 115.608 18.4585Z"
                  fill="#3D75E4"/>
              <path
                  d="M114.492 19.1108C116.907 20.5136 119.313 22.9764 121.15 26.1725L119.036 27.384C117.825 25.2639 116.202 23.5503 114.484 22.546C112.758 21.5418 111.143 21.3823 109.916 22.0837L107.818 18.4254C109.663 17.3653 112.078 17.7081 114.492 19.1108Z"
                  fill="#81ABEE"/>
              <path
                  d="M114.516 11.4386C118.538 13.7739 122.306 17.7829 125.14 22.7165L123.026 23.928C120.754 19.9668 117.73 16.7548 114.508 14.8817C111.286 13.0087 108.254 12.6979 105.966 14.013L103.868 10.3546C106.718 8.72074 110.494 9.10331 114.516 11.4386Z"
                  fill="#81ABEE"/>
              <path
                  d="M115.633 10.7839C112.997 9.25359 110.464 8.56018 108.223 8.72756C107.052 8.81523 105.959 9.14201 104.985 9.69993L103.869 10.3535C106.719 8.71959 110.503 9.10216 114.517 11.4374C118.539 13.7727 122.307 17.7818 125.141 22.7153L126.257 22.0618C123.423 17.1282 119.647 13.1192 115.633 10.7839Z"
                  fill="#3D75E4"/>
              <path
                  d="M115.655 2.85998C110.137 -0.344064 104.936 -0.87807 101.025 1.36954L99.9092 2.0231C103.82 -0.22451 109.021 0.301526 114.539 3.51354C120.057 6.71758 125.234 12.217 129.122 18.9997L130.238 18.3462C126.351 11.5715 121.173 6.06402 115.655 2.85998Z"
                  fill="#3D75E4"/>
              <path
                  d="M114.539 3.51467C120.057 6.71871 125.234 12.2182 129.122 19.0009L127.008 20.2123C123.683 14.41 119.242 9.69958 114.523 6.95782C109.805 4.20808 105.356 3.76175 102.007 5.68258L99.9092 2.02424C103.82 -0.223373 109.021 0.302663 114.539 3.51467Z"
                  fill="#81ABEE"/>
            </svg>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhereToGo',

  props: {
    filter: String,
  },

  mounted() {
    this.$http.get(
        `${process.env.VUE_APP_DEFAULT_DEVELOP_HOST}api/ui/panels?type=${this.filter}`).
        then(data => {
          this.summary = data.data.data;
        });
  },

  data: function() {
    return {
      summary: [],
      colors: ['bg--violet', 'bg--orange', 'bg--blue', 'bg--green', 'bg--red'],
    };
  },
  methods:{
    getRoute(type) {
      return {name: 'MainViewOrganizations', params: {type: this.getEduTypesIds(type)}};
    },
    getEduTypesIds(type) {
      return type.items.reduce((result, item) => {
        if (item.dictionary_id.type.id === 10) {
          result.push(item.dictionary_id);
        }
        return result;
      }, []);
    },
    getLinkForItem(item) {
      if (item.dictionary_id.type.id === 10) {
        this.$router.push({name: 'MainViewOrganizations', params: {type: [item.dictionary_id]}});
      }
    },
  }

};
</script>
<style>
.types {
  justify-content: space-between;
  flex-wrap: nowrap;
}

.type {
  margin-right: 24px;
}
</style>
